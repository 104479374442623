import { sendRequest, sendRequest2Front } from '../../utils/request';

const UAParser = require('../../utils/ua-parser.min.js');

const parser = new UAParser();
const result = parser.getResult();
const ua_info = JSON.stringify(result);
// 网页性能配置
const PerformanceConfig = {
  EventType: 600000,
  EventID: 890001,
};

function getPerformanceData(CRGTA_CONFIG) {
  const { timing, navigation } = window.performance;
  return {
    PNAME: CRGTA_CONFIG.project_name,
    PNAME_NOTE: window.document.title,
    URL: `${window.location.origin}${window.location.pathname}${window.location.hash}`,
    UA_INFO: ua_info,
    BRO_TYPE: CRGTA_CONFIG.browser_type,
    PN_TYPE: navigation.type, // 页面来源类型
    PN_RC: navigation.redirectCount, // 网页经过了多少次重定向跳转
    PN_DNS: timing.domainLookupEnd - timing.domainLookupStart, // DNS查询耗时
    PN_TCP: timing.connectEnd - timing.connectStart, // TCP链接耗时
    PN_REQ: timing.responseEnd - timing.responseStart, // request请求耗时,内容加载耗时
    PN_DOM: timing.domComplete - timing.domInteractive, // 解析dom树耗时
    PN_WHITE: timing.responseStart - timing.navigationStart, // 白屏时间
    PN_READY: timing.domContentLoadedEventEnd - timing.navigationStart, // domready时间
    PN_ONLOAD: timing.loadEventEnd - timing.navigationStart, // onload时间
    version_host: window.crgt && window.crgt.clientVersion, // 客户端版本
  };
}
// 获取性能，抽样30%
export const getPerforms = ({ CRGTA_CONFIG, getRequestData }) => {
  // 上报性能
  const percent = 30;
  if (Math.floor(Math.random() * 100) < percent) {
    window.onload = () => {
      setTimeout(() => {
        if (window.performance && window.performance.timing) {
          const reqData = getRequestData({
            EventType: PerformanceConfig.EventType,
            EventID: PerformanceConfig.EventID,
            Body: {
              ...getPerformanceData(CRGTA_CONFIG),
            },
          });
          sendRequest({ CRGTA_CONFIG, data: reqData }); // 发送请求到大数据
          sendRequest2Front({
            CRGTA_CONFIG,
            data: reqData,
            url: '/receive/performance',
          }); // 发送请求到前端服务器
        }
      }, 0);
    };
  }
};

// 第一页最后一个请求完成时
export const trackFirstPageLastRequestTime = ({ CRGTA_CONFIG, getRequestData }) => {
  if (window.performance && window.performance.timing) {
    const reqData = getRequestData({
      EventType: PerformanceConfig.EventType,
      EventID: PerformanceConfig.EventID,
      Body: {
        ...getPerformanceData(CRGTA_CONFIG),
        FPR_time: Math.floor(Date.now() - window.performance.timing.navigationStart),
      },
    });
    sendRequest({ CRGTA_CONFIG, data: reqData }); // 发送请求到大数据
    sendRequest2Front({
      CRGTA_CONFIG,
      data: reqData,
      url: '/receive/performance',
    }); // 发送请求到前端服务器
  }
};
