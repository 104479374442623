import configFn from '../config';

const UAParser = require('./ua-parser.min');

const parser = new UAParser(); // ua_info
const project_name = '其他'; // 项目名称
const browser_type = parser.getBrowser().name || 'other'; // 浏览器类型/CRGT_APP
export default {
  appKey: configFn(false).appKey,
  api_base: configFn(false).api_base, // 服务端地址
  api_base_front: configFn(false).api_base_front, // 前端接口地址(性能、错误埋点)
  api_base_analytics: configFn(false).api_base_analytics, // 大数据
  prefix: '_crgta_', // session prefix
  debug: false,
  project_name,
  browser_type,
  LOCAL_S: [], // localstorage array key
};
