import axios from 'axios';
import { build, getToken, setUserInfo, clearToken, compare } from './core';
import { isProd } from './platform';
import { parseUrl } from './util';
import * as CRGTA from '../analytics/src/h5';
import { EncryptUtil } from '@crgt/fe-encrypt-util';
import * as _ from 'lodash';
const axiosInstance = axios.create({
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
  },
});

/**
 * @namespace request
 */
export default build('request')((invoke) => ({
  /**
   * request请求
   * app走客户端请求(低于1.2.6版本走h5请求);
   * 浏览器使用axios请求，参数中包括了通用请求参数，token等，默认post请求
   * 浏览器其他请求设置参考axios配置
   * @function request.post
   * @param {Object} params 参数
   * @param {Number} params.isSign=0   0 非加密 1 加密
   * @param {Boolean} params.withoutLogin=false 是否需要登录，默认需要登录(注意，客户端里withoutLogin不会带上token，H5会,
   * 如果不需要登录态但也带上token，请使用H5请求:useNative:false）
   * @param {Boolean} params.withoutToken=false 请求不要带上token, withoutLogin和withoutToken不要一起使用
   * @param {Number} params.signLevel = 0 加密等级（小程序、H5）,isSign=2,
   *  0：("无任何校验"),2：("H5-3级校验,入参加密,回参加密,校验sign");
   * @param {String} params.host='' 请求域名， 默认测试环境t-apicloud.ccrgt.com， 正式环境 apicloud.ccrgt.com
   * @param {String} params.path='' 请求路径
   * @param {String} params.url='' 请求url，url和host、path 二者选一填写
   * @param {Object} params.params={} 请求参数
   * @params {Boolean} params.useNative=true 在1.2.6版本以上是否使用客户端请求
   * @return {Promise}  1.1.3及以下版本，请兼容错误信息：res.errmsg || res.errMsg
   *
   * @example
   * window.crgt.request.post({
   *  isSign: 2,
   *  host: 't-apicloud.ccrgt.com',
   *  withoutLogin: false,
   *  path: 'crgt-test/retail-takeout/cashback/get_cashback_list'
   * }).then((res)=>{  // 客户端未登录返回code为-1011， 后台一般为14001,14002，所以code === -1011 || code ===
   * 14001 || code === 14002 表示token校验不通过
   *  console.log(res); // {code, data}
   * });
   *
   * @support ios 1.2.6
   * @support android 1.2.6
   * @support browser, wxmp, qqmp
   */
  post: {
    common: function ({
      isSign = 0,
      withoutLogin = false,
      withoutToken = false,
      host = `${isProd ? '' : 't-'}apicloud.ccrgt.com`,
      path = '',
      url = '',
      params = {},
      useNative = true,
    }) {
      path = path.replace(/^\//, '');
      // 2.1.6版本以下不需要登录使用H5请求，get请求使用H5请求
      if (
        useNative &&
        ((compare('1.2.6') > 0 && !withoutLogin) || compare('2.1.6') >= 0)
      ) {
        if (url) {
          const parsedUrl = parseUrl(url);
          host = parsedUrl.hostname;
          path = parsedUrl.pathname.replace(/^\//, '');
        }
        return invoke('post', {
          isSign,
          host,
          path,
          params,
          withoutLogin: withoutLogin || withoutToken,
        });
      } else {
        return request({
          isSign,
          withoutLogin,
          host,
          path,
          url,
          params,
          withoutToken,
        });
      }
    },
    browser: (paramsArg = {}) => {
      return request(paramsArg);
    },

    support: {
      iOS: '1.2.6',
      android: '1.2.6',
      browser: 0,
      mp: 0,
    },
  },
}));

const request = (paramsArg) => {
  const {
    host,
    path,
    isSign = 0,
    params = {},
    withoutToken,
    url = '',
    method = 'post',
    ...rest
  } = paramsArg || {};

  let queryUrl = url;
  if (!queryUrl) {
    queryUrl = `https://${host}/${path.replace(/^\//, '')}`;
  }

  const newIsSign = isSign === 0 ? 0 : 2;
  const env = isProd ? 'prod' : 'dev';
  const encryptUtil = new EncryptUtil(env);
  return new Promise((resolve, reject) => {
    getToken().then((token) => {
      const encryptPostData = encryptUtil.encryptData(newIsSign, params, token);
      const requestData = withoutToken
        ? _.omit(encryptPostData, ['token'])
        : encryptPostData;

      const start_at = Date.now();
      let api_code = -1;
      axiosInstance
        .request({
          method,
          url: queryUrl,
          data: requestData,
          ...rest,
        })
        .then((response) => {
          let { data = {} } = response;
          // if (data.isSign === 1 && data.data) { // 解密
          //   data.data = Decrypt(data.data);
          // }
          data = encryptUtil.decryptData(data);
          // token过期
          if (data.code === 14002 || data.code === 14001) {
            setUserInfo({});
            clearToken();
          }
          // sdk返回errMsg
          if (data.code !== 0 && !data.errMsg) {
            data.errMsg = data.errmsg;
          }
          api_code = data.code;
          resolve(data);
        })
        .catch((error) => {
          reject({
            code: -1,
            data: error,
          });
        })
        .finally((res) => {
          const end_at = Date.now();
          CRGTA.Event.trackApiPerformance({
            api_url: queryUrl,
            start_at,
            end_at,
            during: end_at - start_at,
            api_code,
          });
        });
    });
  }).catch((e) => {});
};
