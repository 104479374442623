import {
  build
} from './core';

/**
 * @namespace device
 */
export default build('device')((invoke) => ({
  /**
   * 获取设备信息
   * @function device.getDeviceInfo
   * @support iOS 1.1
   * @support Android 1.1
   * @example
   * window.crgt.device.getDeviceInfo().then((res)=>{
   *  console.log(res);
   * })
   * // 回参
   * {
   *    code: 0,
   *    data: {
   *        systemName: '',  //系统名，如"iPhone OS"
   *        systemVersion: '', // 系统版本，如"6.0"
   *        model: '', // 机器系列，如"iPhone", "iPod touch"
   *        modelVersion: '',  机型，如"iPhone 6"
   *        identifier: '', //设备唯一标识, 每个APP获取到的均不同
   *        cguid: '' //  客户端cguid
   *    }
   * }
   */
  getDeviceInfo: {
    common: function () {
      return invoke('getDeviceInfo', {});
    },
    support: {
      iOS: '1.1',
      android: '1.1',
    }
  },
  /**
   * 获取网络类型
   * @function device.getNetworkType
   * @support iOS 1.1
   * @support Android 1.1
   * @support wx
   * @example
   * window.crgt.device.getNetworkType().then((res)=>{
   *   console.log(res);
   * })
   *
   * // 回参
   * {
   *   code: 0,
   *   data: {
   *     type: 1,
   *   }
   * }
   *
   *    0: 未知类型网络
   *    1: 高铁WiFi
   *    2: WiFi
   *    3: 2G
   *    4: 3G
   *    5: 4G
   *    6: 5G
   */
  getNetworkType: {
    common: function () {
      return invoke('getNetworkType', {});
    },
    wx: () => {
      return new Promise((resolve, reject) => {
        window.wx && window.wx.getNetworkType({
          success: function (res) {
            const map = {
              '2g': 3,
              '3g': 4,
              '4g': 5,
              'wifi': 2
            };

            resolve({
              code: 0,
              data: {
                type: map[res.networkType] || 0
              }
            });
          }
        });
      }).catch((e) => {});
    },
    support: {
      iOS: '1.1',
      android: '1.1',
      wx: 0
    }
  }
}));
