import {
  build
} from './core';

/**
 * @namespace sensor
 */
export default build('sensor')((invoke) => ({
  /**
   * 获取地址信息
   * @function sensor.getLocation
   * @param params.cacheTimeout=30 {Number} 纬度定位缓存过期时间，单位秒。使用缓存会加快定位速度，缓存过期会重新定位
   * @param params.timeout=10 {Number}定位超时失败回调时间，单位秒。
   * @support android 1.1
   * @support ios 1.1
   * @example
   * crgt.sensor.getLocation().then((res)=>{});
   * // 回参
   * {
   *
   *   code: 0,  // 0   成功；12   GPS打开，但定位失败； 13    获取地理位置信息失； 14    定位超时； 15    网络错误；16    GPS未打开或用户未授权
   *   data: {
   *   longitude: '', // 经度
   *   latitude: '',  // 纬度
   *   country: '', // 国家名
   *   province: '', // 省份名
   *   city: '', // 城市名
   *   }
   * }
   */
  getLocation: {
    common: function ({
      cacheTimeout = 30,
      timeout = 10
    }) {
      return invoke('getLocation', {
        cacheTimeout,
        timeout
      });
    },
    support: {
      iOS: '1.1',
      android: '1.1'
    }
  }
}));
