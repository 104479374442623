import {
  build, ready
} from './core';
import { isProd, isWXMp } from './platform';

/**
 * @namespace share
 */
let shareInfo = {
  title: document.title, // 分享标题
  desc: '', // 分享描述
  link: window.location.href, // 分享链接，该链接域名必须与当前企业的可信域名一致
  imgUrl: '', // 分享图标
  // type: 1, // 1:微信， 2:微信朋友圈， (预留：qq 、 qq空间、微博),不能写，否则shouShareMenu会直接分享了
  direcrCb: true, // android 在点击按钮的时候就回调，默认false(android 1.2.8版本）,ios都是点击就回调
  success: function () {
    // 用户确认分享后执行的回调函数
  },
  cancel: function () {
    // 用户取消分享后执行的回调函数
  },
  // style: 1, // 分享风格(默认是分享H5参数为0或者不传, 参数为1是为小程序卡片风格)
  // 小程序相关
  userName: 'gh_345c5d37f42f',
  miniProgramType: isProd ? 0 : 2,
  path: ''  // 拉起小程序页面的路径，不填默认拉起小程序首页,H5为url地址
};

export default build('share')((invoke) => ({
  /**
   * 直接分享到
   * 无法判断是否真正分享成功
   * @function share.shareTo
   * @param params.type {Number} 1:微信， 2:微信朋友圈， (预留：qq 、 qq空间、微博)
   * @param params.style {Number} 分享风格(0：默认是分享H5参数为0或者不传, 1：小程序卡片风格)
   * @param params.title {String} 标题
   * @param params.desc='' {String}  分享内容摘要 （朋友圈小程序没有）
   * @param params.link='' {String} 分享的url（如果不支持小程序，url作为小程序风格的降级）
   * @param params.imgUrl='' {String} 分享图标（小程序的卡片图片，固定格式）
   * @param params.path='' {String} 分享的小程序也页面（分享到小程序才需要传path）
   * @param params.userName='gh_345c5d37f42f' {String} 小程序的原始id, 高铁出行： gh_345c5d37f42f
   * @param params.miniProgramType=0 {Number}（未传，测试app为开发版，正式版app为正式版), 0:正式版本， 1: 开发版，2:体验版（小程序提测版本为体验版）
   * @support ios 1.1
   * @support android 1.1
   * @example
   * crgt.share.shareTo({
   *   title: '',
   *   desc: '',
   *   link: '',
   *   imgUrl: '',
   * }).then((res)=>{
   *   console.log(res); // {code: 0},  0成功，1失败，2取消
   * });
   */
  shareTo: {
    common: function (params) {
      const options = Object.assign({}, shareInfo, params || {});
      return new Promise((resolve, reject) => {
        invoke('shareTo', {
          ...shareInfo, ...params
        }).then((res) => {
          if (res) {
            if (res.code === 0) {
              options.success(res);
            } else if (res.code === 2) {
              options.cancel(res);
            }
            resolve(res);
          } else {
            reject(res);
          }
        }, () => {
          reject();
        });
      }).catch((e) => {});
    },
    support: {
      iOS: '1.1',
      android: '1.1'
    }
  },
  /**
   * 拉起分享面板
   * @function share.showShareMenu
   * @param params.direcrCb {Boolean} android 在点击按钮的时候就回调，默认false(android 1.2.8版本）,ios都是点击就回调
   * @param params.title {String} 标题
   * @param params.link='' {String} 分享的url（如果不支持小程序，url作为小程序风格的降级）
   * @param params.desc='' {String}  分享内容摘要 （朋友圈小程序没有）
   * @param params.imgUrl='' {String} 分享图标（小程序的卡片图片，固定格式）
   * @param params.includeTypes = [] {Array} 分享对话框包含分享渠道 （1：微信，2：朋友圈，3：QQ），不传或空数组则全部显示
   * @example
   * // 回参
   * // 分享到qq：ios：v1.2.6， android：v1.2.9）
   * {
   *  code: 0, // 0成功，1失败，2取消
   *  data: {
   *      type: 1, // 1:微信， 2:微信朋友圈， 3:qq  (预留：qq空间  微博);
   *  }
   * }
   * @support ios 1.1
   * @support android 1.1
   * @example
   * crgt.share.showShareMenu({
   *   title: '',
   *   link: '',
   *   desc: '',
   *   imgUrl: '',
   *   imgList: '',
   * }).then((res)=>{
   *   console.log(res); // {code: 0},  0成功，1失败，2取消
   * });
   */
  showShareMenu: {
    common: function (params) {
      const options = Object.assign({}, shareInfo, params || {});
      return new Promise((resolve, reject) => {
        let info = {
          ...shareInfo, ...params
        };
        try {
          delete info.type;
        } catch (e) {
          console.warn(e);
        }
        invoke('showShareMenu', info).then((res) => {
          if (res) {
            if (res.code === 0) {
              options.success(res);
            } else if (res.code === 2) {
              options.cancel(res);
            }
            resolve(res);
          } else {
            reject(res);
          }
        }, () => {
          reject();
        });
      }).catch((e) => {});
    },
    support: {
      iOS: '1.1',
      android: '1.1'
    }
  },
  // /**
  //  * 拉起小程序
  //  *
  //  * @function share.launchMiniProgram
  //  * @param params.path='' {String} 分享的小程序也页面（分享到小程序才需要传path）
  //  * @param params.userName='gh_345c5d37f42f' {String} 小程序的原始id, 高铁出行： gh_345c5d37f42f
  //  * @param params.miniProgramType=0 {Number}（未传，测试app为开发版，正式版app为正式版), 0:正式版本， 1: 开发版，2:体验版（小程序提测版本为体验版）
  //  * @support ios 1.1
  //  * @support android 1.1
  //  * @example
  //  * crgt.share.launchMiniProgram({
  //  *   path: '',  // 小程序路径， 小程序活动路径： `/pages/activity/index?url=${encodeURIComponent(url)}`
  //  * })
  //  */
  // launchMiniProgram: {
  //   common: function (params) {
  //     const options = Object.assign({}, shareInfo, params || {});
  //     return invoke('launchMiniProgram', options);
  //   },
  //   support: {
  //     iOS: '1.1',
  //     android: '1.1'
  //   }
  // },
  /**
   * 设置通用分享信息，设置微信分享信息
   * @function share.setShareInfo
   * @support ios 1.1
   * @support android 1.1
   * @support wx
   * @example
   * // 默认分享信息
   * {
   *  title: '', // 分享标题
   *  desc: '', // 分享描述
   *  link: '', // 分享链接，该链接域名必须与当前企业的可信域名一致
   *  imgUrl: '', // 分享图标
   *  type: 1, // 1:微信， 2:微信朋友圈， (预留：qq 、 qq空间、微博)
   *  direcrCb: true, // android 在点击按钮的时候就回调，默认false(android 1.2.8版本）,ios都是点击就回调
   *  success: function () {
   *    // 用户确认分享后执行的回调函数
   *  },
   *  cancel: function () {
   *    // 用户取消分享后执行的回调函数
   *  },
   *    style: 0, // 分享风格(默认是分享H5参数为0或者不传, 参数为1是为小程序卡片风格)
   *  // 小程序相关
   *  userName: 'gh_345c5d37f42f',
   *  miniProgramType: isProd ? 0 : 2,
   *  path: ''  // 拉起小程序页面的路径，不填默认拉起小程序首页,H5为url地址
   * }
   */
  setShareInfo: {
    common: (info) => {
      shareInfo = { ...shareInfo, ...info };
    },
    wx: (info) => {
      shareInfo = { ...shareInfo, ...info };
      ready(() => {
        // 兼容旧版本微信
        // window.wx.updateAppMessageShareData(shareInfo);
        // window.wx.updateTimelineShareData(shareInfo);
        window.wx.onMenuShareTimeline(shareInfo);
        // 分享给朋友
        window.wx.onMenuShareAppMessage(shareInfo);
        // 分享到QQ
        window.wx.onMenuShareQQ(shareInfo);
        // 分享到腾讯微博
        window.wx.onMenuShareWeibo(shareInfo);
        // 分享到QQ空间
        window.wx.onMenuShareQZone(shareInfo);
      });
    },
    mp: (info) => {
      shareInfo = { ...shareInfo, ...info };
      const url = (shareInfo.link || '').replace(/mpCode=[^&]+(&|$)/, '');
      ready(() => {
        (isWXMp ? window.wx : window.qq).miniProgram.postMessage({
          data: {
            title: shareInfo.title,
            desc: shareInfo.desc,
            imgUrl: shareInfo.imgUrl,
            path: shareInfo.path || `/pages/activity/index?url=${encodeURIComponent(url)}`
          }
        }
        );
      });
    },
    support: {
      iOS: '1.0',
      android: '1.0',
      wx: '0'
    }
  }
}));
