/*
 * @Author: Lina
 * @Date: 2020-06-09 15:59:56
 * @LastEditors: Lina
 * @LastEditTime: 2020-09-24 10:04:20
 */
const wxSdkUrl = 'https://static.ccrgt.com/assets/js/weixin/jweixin-1.4.0.js';
const qqSdkUrl = 'https://static.ccrgt.com/assets/js/qq/qqjssdk-1.0.0.js';
const sentrySdkUrl = 'https://static.ccrgt.com/assets/js/sentry/bundle-5.5.0.min.js';
const swanSdkUrl = 'https://static.ccrgt.com/assets/js/swan/swan-2.0.6.js';
const APPID = 'wxf6076a6c59a2f14e'; // 微信小程序 高铁出行appid
const JSDCAPPID = 'wxb31b88f38504e767'; // 极速打车微信小程序 appid
const SWANAPPID = '21506300';

const TOKENKEY = '_ACCESSTOKEN_';
const REFRESH_TOKEN_KEY = '_REFRESH_TOKEN_';
const USERINFO_KEY = '_USER_INFO_';

let apiPreifx = '';
let loginUrl = '';
let wxGetUserInfoUrl = '';
let wxGetApiSignUrl = '';
let qqGetUserInfoUrl = '';
let refreshTokenUrl = '';
let getUserInfoUrl = '';

let regUrl = ''; // h5登陆接口url
let verifyCodeUrl = ''; // h5验证码接口

const initConstant = (isProd) => {
  loginUrl = 'https://static.ccrgt.com/rollupEmbedLogin/js/embedLogin.js';
  apiPreifx = isProd ? 'https://apicloud.ccrgt.com/crgt' : 'https://t-apicloud.ccrgt.com/crgt-test';
  wxGetUserInfoUrl = `${apiPreifx}/user-center/user/thridpart`;
  wxGetApiSignUrl = `${apiPreifx}/component/jsapi/sign`;
  qqGetUserInfoUrl = `${apiPreifx}/user-center/user/qqMinipart`;
  regUrl = `${apiPreifx}/user-center/userActivity/reg`;
  verifyCodeUrl = `${apiPreifx}/user-center/userActivity/getVerifycode`;
  /**
   * refreshToken
   */
  refreshTokenUrl = `${apiPreifx}/user-center/userActivity/tokenRent`;
  /**
   * 获取用户信息
   * @see https://api-manager.ccrgt.com/doc/dwKKRANfu
   */
  getUserInfoUrl = `${apiPreifx}/user-center/userActivity/getUserInfo`;

};

export {
  initConstant,
  loginUrl,
  wxSdkUrl,
  qqSdkUrl,
  swanSdkUrl,
  sentrySdkUrl,
  wxGetUserInfoUrl,
  wxGetApiSignUrl,
  qqGetUserInfoUrl,
  regUrl,
  verifyCodeUrl,
  refreshTokenUrl,
  getUserInfoUrl,
  APPID,
  JSDCAPPID,
  SWANAPPID,
  TOKENKEY,
  REFRESH_TOKEN_KEY,
  USERINFO_KEY
};
