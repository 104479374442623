// 默认初始化参数
import configFn from '../config';

export default {
  appKey: configFn(false).appKey,
  AV: '1.0.0',
  PNAME: `${window.location.protocol}//${window.location.host}/${window.location.pathname
    .split('/')
    .filter(obj => obj)[0] || ''}`,
  CHID: 'other', // 渠道来源
  UID: 'other',
  DEBUG: window.location.host !== 'static.ccrgt.com',
  LOCAL_S: [], // localstorage array key
};
