import axios from 'axios';
import { qqGetUserInfoUrl, qqSdkUrl } from './utils/constants';
import { loadScript } from './util';
import { getConfig } from './config';

const { USEQQSDK } = getConfig();

/**
 * 初始化微信
 * 1）加载微信sdk
 * 2）若时微信浏览器，通过微信config接口注入权限验证配置
 * @return {Promise<any>}
 * @private
 */
function initQQ() {
  return new Promise((resolve, reject) => {
    if (USEQQSDK) {
      loadScript(qqSdkUrl).then(() => {
        resolve();
      });
    } else {
      resolve();
    }
  }).catch((e) => {});
}

let GETTOKEN_LOCK = false;
let curGetTokenPromise = null;
/**
 * 小程序里获取用户信息、token
 * @param jscode {string} 小程序url中带的参数code
 * @return {Promise<any>}
 * @private
 */
const getQQTokenByCode = (jscode) =>{
  if (GETTOKEN_LOCK) {
    return curGetTokenPromise;
  } else {
    GETTOKEN_LOCK = true;
    curGetTokenPromise = new Promise((resolve, reject) => {
      _getQQTokenByCode(jscode).then((token) => {
        GETTOKEN_LOCK = false;
        resolve(token);
      });
    }).catch((e) => {});
    return curGetTokenPromise;
  }
};

const _getQQTokenByCode = (jscode) => {
  return new Promise((resolve, reject) => {
    axios.post(
      qqGetUserInfoUrl,
      {
        params: {
          jscode,
        },
        isSign: '',
        sign: '',
        cguid: '',
        timeStamp: ''
      }
    ).then((result) => {
      const res = result.data || {};
      if (res && res.code === 0) {
        const { nickName, openId, icon, mobile, token, userNo } = res.data || {};
        return resolve({
          code: 0,
          data: {
            nickName,
            phoneNumber: mobile,
            avatar: icon,
            openId,
            accessToken: token,
            accountId: userNo
          }
        });
      } else {
        // QQ小程序一定要先登录
   //     window.qq.miniProgram.navigateTo(`/pages/login/index?redirectUrl=${encodeURIComponent(window.location.href)}`);
        return resolve({code: -1});
      }
    });
  }).catch((e) => {});
};

export {
  initQQ,
  getQQTokenByCode
};
