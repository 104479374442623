/* eslint-disable no-param-reassign */
import configFn from '../config';

export const setInitData = ({
  newOptions,
  CRGTA_CONFIG,
  requestCommonData,
  requestHeaderData,
}) => {
  // appKey
  CRGTA_CONFIG.appKey = newOptions.appKey;
  // 设置app version
  requestCommonData.AV = newOptions.AV;
  // 项目名
  CRGTA_CONFIG.project_name = newOptions.PNAME;
  // localstorage
  if (Array.isArray(newOptions.LOCAL_S)) {
    CRGTA_CONFIG.LOCAL_S = newOptions.LOCAL_S;
  }
  // 渠道ID
  requestCommonData.CHID = newOptions.CHID;
  // UID用户ID token（可选，客户端获取，小程序获取）
  requestHeaderData.UID = newOptions.UID;
};

export const setDebug = ({ CRGTA_CONFIG }) => {
  CRGTA_CONFIG.debug = true;
  CRGTA_CONFIG.appKey = configFn(true).appKey;

  CRGTA_CONFIG.api_base = configFn(true).api_base;
  CRGTA_CONFIG.api_base_front = configFn(true).api_base_front;
  CRGTA_CONFIG.api_base_analytics = configFn(true).api_base_analytics;
};
