// 追踪接口性能
import { sendRequest2Analytics } from '../../utils/request';

function _getEID(isDebug) {
  return isDebug ? 'o_customize_performance_16' : 'o_customize_tech_3';
}

export const trackApiPerformance = ({ CRGTA_CONFIG, getRequestData, BizData }) => {
  const {
    api_url, start_at, end_at, during, api_code,
  } = BizData;
  const reqData = getRequestData({
    EventID: _getEID(),
    Body: {
      api_url,
      start_at,
      end_at,
      during,
      api_code, // 失败都是 -1
      client: 1, // 1网页，2 小程序
    },
  });
  sendRequest2Analytics({ CRGTA_CONFIG, data: reqData }); // 发送请求到大数据
};
