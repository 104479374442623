/* eslint-disable no-unused-expressions */
import { version } from './package.json';
import defaultCrgtaConfig from './utils/defaultCrgtaConfig';
import defaultValue from './utils/defaultValue';
import defaultInitOptions from './utils/defaultInitOptions';
import { setInitData, setDebug } from './utils/init';
import { setSID, getUSERID, setUSERID } from './utils/userid';
import { sendRequest } from './utils/request';
import { checkIsInApp } from './utils/checkIsInApp';
import eventFn from './utils/event';

import { getPerforms } from './components/performances';
import { getError } from './components/errors';

import { getPerforms as getPerformsOld } from './components/performances/index.old';

require('./utils/Polyfill');

export const __VERSION__ = version;
// 通用配置、公共字段
const CRGTA_CONFIG = defaultCrgtaConfig;
// 请求数据，埋点公共字段
const requestCommonData = defaultValue.COMMON;
// 累计时间公共字段
const requestHeaderData = defaultValue.DATAS[0].HEADER;

// 请求任务列表
const request_arr = [];
let READY = false;

// 获取请求数据
function getRequestData({
  EventType, EventID, Body, UID,
}, startTime = 0) {
  // header
  const USERID = getUSERID({ CRGTA_CONFIG }) || setUSERID({ CRGTA_CONFIG });
  requestCommonData.IMEI = USERID;
  requestHeaderData.CT = Date.now();
  requestHeaderData.ET = EventType;
  requestHeaderData.EID = EventID;
  requestHeaderData.UID = UID || window._CRGT_UID_ || window._ACCESSTOKEN_ || 'other';
  // data
  const requestData = {
    COMMON: requestCommonData,
    DATAS: [
      {
        HEADER: requestHeaderData,
        BODY: Object.assign({}, Body, {
          HB: startTime,
          USERID,
        }),
      },
    ],
  };
  return requestData;
}

function realSendRequest(config, startTime = 0) {
  ready(() => {
    const data = getRequestData(config, startTime);
    sendRequest({ CRGTA_CONFIG, data });
  });
}
/**
 * @namespace H5_App
 */
/**
  * H5 埋点
  * @function H5_App.init
  * @param [appKey=Prod:mwogcduujv5ys8y,Dev:iznkqjnftzekoao] {String} appKey
  * @param [AV=1.0.0] {String} app version
  * @param [PNAME=protocol + host + pathname.split('/').filter(obj => obj)[0] || ''] {String} 页面地址：https://static.crgt.com/orderlists
  * @param [CHID=other] {String} 渠道来源
  * @param [UID=other] {String} (选填) token不填，app内部将自动获取window._ACCESSTOKEN_，小程序url的query：token
  * @param [DEBUG=window.location.host !== 'static.ccrgt.com'] {Boolean} 是否发送到测试环境, 不传将自动判断
  * @param [LOCAL_S=[]] {Boolean} 需要上报的localstorage array key
  * @example
  * // <script src="https://static.ccrgt.com/lib/crgta_analysis.min.js"></script>
  * <script src="https://static.ccrgt.com/lib/crgt_sdk.1.1.5.js"></script>
  *
  * <script>
  *   CRGTA.APP.init({
  *     UID: '',
  *     LOCAL_S: ['keyname'],
  *   });
  * </script>
  */
export const APP = {
  init(options = {}) {
    const newOptions = Object.assign(defaultInitOptions, options);
    // 初始化信息
    setInitData({
      newOptions,
      CRGTA_CONFIG,
      requestCommonData,
      requestHeaderData,
    });
    // 测试
    newOptions.DEBUG && setDebug({ CRGTA_CONFIG });

    // 设置sessionId
    requestCommonData.SID = setSID();
    // 如果在App中，将一些能够更准确的数据替换出来
    checkIsInApp({
      CRGTA_CONFIG,
      requestHeaderData,
      requestCommonData,
    }).then(() => {
      // 获取性能 => 大数据后台
      getPerforms({ CRGTA_CONFIG, getRequestData, isDebug: newOptions.DEBUG });
      // 获取性能 => 前端后台
      // getPerformsOld({ CRGTA_CONFIG, getRequestData });
      // 获取错误
      getError({ CRGTA_CONFIG, getRequestData });

      READY = true;
      ready();
    });
  },
};

export const Event = eventFn({
  CRGTA_CONFIG,
  realSendRequest,
  getRequestData,
});

export const ready = (cb) => {
  if (cb) { request_arr.push(cb); }

  if (READY) {
    for (let i = request_arr.length - 1; i >= 0; i--) {
      request_arr[i]();
      request_arr.pop();
    }
  }
};

export const setReady = (flag) => {
  READY = flag;
};
