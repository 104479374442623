/**
 * @namespace data
 */
import {
  build,
  getUserInfo,
  setUserInfo
} from './core';
import { parseUrlQuery } from './util';
import { getWXTokenByCode } from './wx';
import { getQQTokenByCode } from './qq';
import { getSwanTokenByCode } from './swan';
import { isWXMp, isSwanMp } from './platform';

export default build('data')((invoke) => ({
  // /**
  //  * @function data.getCarInfo
  //  * @desc 获取当前车次信息
  //  * @memberOf data
  //  * @example
  //  * crgt.data.getCarInfo().then((res)=>{
  //  *  console.log(res);
  //  * });
  //  * // 回参
  //  * {
  //  *   code: 0
  //  *   data: {
  //  *     carNumber: '', //车体号
  //  *   }
  //  * }
  //  * @support iOS 1.1
  //  * @support Android 1.1
  //  */
  // getCarInfo: {
  //   common: function (params) {
  //     return invoke('getCarInfo', params);
  //   },
  //   support: {
  //     iOS: '1.1',
  //     android: '1.1'
  //   }
  // },
  /**
   *
   * @desc 获取用户信息 @support wx
   * @function data.getUserInfo
   * @memberOf data
   * @example
   * crgt.data.getUserInfo().then(()=>{});
   * // 回参
   * {
   *   code: 0
   *   data: {
   *     accessToken: '', //token
   *     phoneNumber: '',  // 手机号
   *     nickName: '', // 昵称
   *     avatar: '', // 头像
   *     accountId: '',
   *   }
   * }
   * @support iOS 1.1
   * @support Android 1.1
   * @support wxmp
   * @support qqmp
   */
  getUserInfo: {
    common: function (params) {
      return invoke('getUserInfo', params).then((res) => {
        if (res && res.code === 0) {
          setUserInfo(res.data || {});
        }
        return res;
      }, (err) => {
        return new Promise(function (resolve, reject) {
          reject(err);
        }).catch((e) => {});
      });
    },
    mp: () => {
      return new Promise(async (resolve, reject) => {
        const info = await getUserInfo();
        if (info.nickName) {
          resolve({
            code: 0,
            data: info
          });
        } else {
          (isSwanMp ? getSwanTokenByCode : (isWXMp ? getWXTokenByCode : getQQTokenByCode))(parseUrlQuery('code')).then((res) => {
            if (res && res.code === 0) {
              setUserInfo(res.data || {});
            }
            resolve(res);
          });
        }
      });
    },
    browser: async () => {
      return Promise.resolve({
        code: 0,
        data: await getUserInfo()
      }).catch((e) => {});
    },
    support: {
      iOS: '1.1',
      android: '1.1',
      wxmp: '0',
      qqmp: '0',
      browser: '0'
    }
  },
  /**
   *
   * @desc 设置用户信息
   * @function data.getUserInfo
   * @memberOf data
   * @example
   * crgt.data.setUserInfo(data));
   * // 入参
   * {
   *     accessToken: '', //token
   *     phoneNumber: '',  // 手机号
   *     nickName: '', // 昵称
   *     avatar: '', // 头像
   *     accountId: '',
   * }
   * @support browser
   */
  setUserInfo: {
    browser: async (data) => {
      setUserInfo(data);
      return Promise.resolve({
        code: 0,
        data: await getUserInfo()
      }).catch((e) => {});
    },
    support: {
      iOS: 0,
      android: 0,
      browser: 0
    }
  },
  // /**
  //  * @function data.getNativeInfo
  //  * @desc 获取客户端信息
  //  * @memberOf data
  //  * @example
  //  * crgt.data.getNativeInfo().then(()=>{
  //  *
  //  * });
  //  *
  //  * @support iOS 1.1
  //  * @support Android 1.1
  //  */
  // getNativeInfo: {
  //   common: function (params) {
  //     return invoke('getNativeInfo', params);
  //   },
  //   support: {
  //     iOS: '1.1',
  //     android: '1.1'
  //   }
  // },
  // /**
  //  * @function data.getAppInfo
  //  * @desc 所有应用自身产生的全局数据相关归拢至此
  //  * @memberOf data
  //  * @example
  //  * crgt.data.getAppInfo().then(()=>{
  //  *
  //  * });
  //  * // 回参
  //  *{
  //  * code: 0,
  //  * data:{
  //  *   blackbox: '', //同盾风控设备指纹
  //  *   carNumber: "",
  //  *   reskUdid: "",
  //  *   did: "",
  //  * },
  //  *}
  //  * @support iOS 2.4.5
  //  * @support Android 2.4.5
  //  */
  // getAppInfo: {
  //   common: function (params) {
  //     return invoke('getAppInfo', params);
  //   },
  //   support: {
  //     iOS: '2.4.5',
  //     android: '2.4.5'
  //   }
  // },
  // /**
  //  * @function data.syncNumberTrip
  //  * @desc 同步客户端行程数据
  //  * @memberOf data
  //  * @example
  //  * crgt.data.syncNumberTrip().then(()=>{
  //  *
  //  * });
  //  * // 回参
  //  *{
  //  * code: 0,
  //  * data:{
  //  *  size:"" // 行程的数量
  //  * },
  //  *}
  //  * @support iOS 3.4.2
  //  * @support Android 3.4.2
  //  */
  // syncNumberTrip: {
  //   common: function (params) {
  //     return invoke('syncNumberTrip', params);
  //   },
  //   support: {
  //     iOS: '3.4.2',
  //     android: '3.4.2'
  //   }
  // }
}));
