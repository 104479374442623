/**
 * @namespace util
 */

import axios from 'axios';

/**
 * 动态加载script
 * @function util.loadScript
 * @param url {string}
 * @return {Promise<any>}
 * @example
 * crgt.util.loadScript('https://res.wx.qq.com/open/js/jweixin-1.4.0.js').then();
 */
const loadScript = (url) => {
  const scriptTag = document.createElement('script');
  scriptTag.src = url;
  scriptTag.type = 'text/javascript';
  document.head.appendChild(scriptTag);
  return new Promise((resolve, reject) => {
    scriptTag.onload = resolve;
  }).catch((e) => {});
};

/**
 * 解析url search
 * @function util.parseUrlQuery
 * @param name {String} 参数名
 * @return {string}
 */
const parseUrlQuery = (name) => {
  let searchParams = window.location.search.slice(1).split('&');
  let hashParams = window.location.hash.slice(3).split('&');
  let params = searchParams.concat(hashParams) || [];
  for (let i = 0; i < params.length; i++) {
    const pair = params[i].split('=');
    if (name === pair[0]) {
      return decodeURIComponent(pair[1]);
    }
  }
};

/**
 * 解析url
 * @function util.parseUrl
 * @param path {String} url
 * @return {{protocol: *, password: *, hostname: *, search: *, port: *, hash: *, username: *, pathname: *}}
 */
const parseUrl = (path) => {
  const a = document.createElement('a');
  a.href = path;
  return {
    protocol: a.protocol,
    username: a.username,
    password: a.password,
    hostname: a.hostname, // host 可能包括 port, hostname 不包括
    port: a.port,
    pathname: a.pathname,
    search: a.search,
    hash: a.hash
  };
};

const axiosInstance = axios.create({
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json'
  }
});

const isArray = (data) => {
  return Object.prototype.toString.call(data) === '[object Array]';
};

const isObject = (data) => {
  return Object.prototype.toString.call(data) === '[object Object]';
};

const deepCopy = (to, from) => {
  let copy;
  const obj = Object.assign({}, to);
  for (let i in from) {
    if (!from.hasOwnProperty(i)) {
      continue;
    }
    copy = from[i];
    if (isArray(copy)) {
      obj[i] = deepCopy([]);
    } else if (isObject(copy)) {
      obj[i] = deepCopy(obj[i] || {}, copy);
    } else {
      obj[i] = from[i];
    }
  }
  return obj;
};

export {
  loadScript,
  parseUrlQuery,
  parseUrl,
  axiosInstance,
  deepCopy
};
