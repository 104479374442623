import {
  build, ready
} from './core';


/**
 * @namespace event
 */
export default build('event')((invoke) => ({
  /**
   * 监听事件
   * @function event.listenNativeEvent
   * @example
   * window.crgt.event.listenNativeEvent(‘orderChange’, ()=>{
   *   window.location.reload();
   * });
   *
   * // 回参
   * {
   *   data: {}  // 该事件传递的数据, orderChange事件 data :  {orderId: ''}
   * }
   * @support iOS 1.2
   * @support android 1.2
   * @deprecated
   */
  listenNativeEvent: {
    common: function (eventName, listener) {
      const cb = listener || function() {};
      ready(() => {
        window.cordova.exec((res)=>{
          cb(res);
        }, ()=>{}, 'event', 'listenNativeEvent', [{
          eventName
        }]);
      });
   },
   support: {
       iOS: '1.2',
       android: '1.2'
   }
  },
  /**
   * 监听事件
   * @function event.on
   * @example
   * window.crgt.event.on(‘orderChange’, ()=>{
   *   window.location.reload();
   * });
   *
   * // 回参
   * {
   *   data: {}  // 该事件传递的数据, orderChange事件 data :  {orderId: ''}
   * }
   * @support iOS 2.2.9
   * @support android 2.2.9
   */
  on: {
    common: function (eventName, listener) {
      const cb = listener || function() {};
      ready(() => {
        window.cordova.exec((res)=>{
          cb(res);
        }, ()=>{}, 'event', 'on', [{
          eventName
        }]);
      });
    },
    support: {
      iOS: '2.2.9',
      android: '2.2.9'
    }
  },
  /**
   * 触发事件
   * @function event.on
   * @example
   * window.crgt.event.emmit(‘orderChange’, {orderId: 1});
   *
   * @support iOS 2.2.9
   * @support android 2.2.9
   */
  emit: {
    common: function (eventName, data = {}) {
      return invoke('emit', {
        eventName,
        data
      });
    },
    support: {
      iOS: '2.2.9',
      android: '2.2.9'
    }
  },
  /**
   * 移除监听事件
   * @function event.off
   * @example
   * window.crgt.event.off(‘orderChange’);
   *
   * @support iOS 2.2.9
   * @support android 2.2.9
   */
  off: {
    common: function (eventName) {
      return invoke('off', {
        eventName
      });
    },
    support: {
      iOS: '2.2.9',
      android: '2.2.9'
    }
  }
}));
