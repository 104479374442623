// 发送请求到大数据
export const sendRequest = function ({ CRGTA_CONFIG, data }) {
  if (CRGTA_CONFIG.debug) { console.warn(data); }
  // 伪装成Image对象，请求后端脚本
  const img = new Image(1, 1);
  const src = `${CRGTA_CONFIG.api_base}?appKey=${CRGTA_CONFIG.appKey}&data=${encodeURIComponent(JSON.stringify(data))}`;
  img.src = src;
};
// 发送请求到前端服务器
export const sendRequest2Front = function ({ CRGTA_CONFIG, data, url }) {
  // if (CRGTA_CONFIG.debug) { console.warn(data); }
  // const img = new Image(1, 1);
  // const src = `${CRGTA_CONFIG.api_base_front}${url}?appKey=${CRGTA_CONFIG.appKey}&data=${encodeURIComponent(JSON.stringify(data))}`;
  // img.src = src;
};

// 发送请求到性能统计服务器
export const sendRequest2Analytics = function ({ CRGTA_CONFIG, data }) {
  if (CRGTA_CONFIG.debug) { console.warn(data); }
  // 伪装成Image对象，请求后端脚本
  const img = new Image(1, 1);
  const src = `${CRGTA_CONFIG.api_base_analytics}?appKey=${CRGTA_CONFIG.appKey}&data=${encodeURIComponent(JSON.stringify(data))}`;
  img.src = src;
};
