// 发送参数默认值以及格式
const UAParser = require('./ua-parser.min');

const parser = new UAParser(); // ua_info

export default {
  COMMON: {
    AV: 'other', // APP版本号数据
    GUID: 'other', // 全局用户ID
    IMEI: 'other', // IMEI
    MAC: 'other', // MAC地址
    CHID: 'other', // 渠道ID
    TO: 'other', // 运营商
    PB: 'other', // 手机品牌标识
    PT: window.navigator.Platform || window.navigator.platform || 'other', // 手机型号
    OSV: parser.getOS().version || 'other', // 手机操作系统版本
    PLAT: 3, // 平台1代表iOS;  2代表android，3代表H5页面，4代表小程序
    LANG: window.navigator.language || 'other', // 系统语言
    SID: '', // 会话ID
  },
  DATAS: [{
    HEADER: {
      CT: 0, // 时间戳
      UID: '', // 用户id
      ET: 0, // 事件类型
      EID: 0, // 事件ID
      CIP: 'other', // 客户端IP
      NT: 0, // 网络类型 0无网络, 1高铁wifi, 2其他WiFi, 3-2g, 4-3g, 5-4g
    },
    BODY: {

    },
  }],
};
