import { parseUrlQuery } from './util';

const ua = navigator.userAgent.toLowerCase();
// const MINI_PROGRAM_FLAG = 'miniprogram';

const REGEXP_IOS = /(iphone os|ipad)/i;

const REGEXP_CRGT = /crgt\/([\d.]+)/i;
// 微信
const REGEXP_WX = /MicroMessenger/i;
// 百度小程序
const REGEXP_SWAN = /swan\//i;


/**
 * qq小程序
 * url中带有mpCode, channel==='qqmp'
 * @type {boolean}
 * @example
 * crgt.isQQMp
 */
const isQQMp = !!parseUrlQuery('mpCode') && parseUrlQuery('channel') === 'qqmp';
// const isQQMp = (window.__wxjs_environment === MINI_PROGRAM_FLAG || ua.indexOf(MINI_PROGRAM_FLAG) > -1) && (ua.indexOf(
//   'qq') > -1);
/**
 * 微信小程序
 * url中带有mpCode, channel==='wxmp'
 * @type {boolean}
 * @example
 * crgt.isWXMp
 */
const isWXMp = !!parseUrlQuery('mpCode') && parseUrlQuery('channel') === 'wxmp';

const isSwanMp = !!parseUrlQuery('mpCode') && parseUrlQuery('channel').includes('swanmp');

// const isWXMp = (window.__wxjs_environment === MINI_PROGRAM_FLAG || ua.indexOf(MINI_PROGRAM_FLAG) > -1) && (ua.indexOf(
//   'qq') === -1);

/**
 * @constant isIOS
 * @type {boolean}
 * @example
 * crgt.isIOS
 */
const isIOS = REGEXP_IOS.test(ua);

/**
 * @type {boolean}
 */
const isAndroid = !isIOS;  // 暂时把非ios的都归为android
/**
 * 微信环境
 * @type {boolean}
 * @example
 * crgt.isWX
 */
const isWX = REGEXP_WX.test(ua);
/**
 * 在国铁吉讯app浏览器中
 * @type {boolean}
 * @example
 * crgt.isCRGT
 */
const isCRGT = REGEXP_CRGT.test(ua);

let tmp = 'browser';
if (isCRGT && isIOS) tmp = 'iOS';
if (isCRGT && isAndroid) tmp = 'android';
if (isWX) tmp = 'wx';
if (isQQMp) tmp = 'qqmp';
if (isWXMp) tmp = 'wxmp';
if (isSwanMp) tmp = 'swanmp';
/**
 *
 * @type {string}
 */
const platform = tmp;

/**
 * 客户端版本号
 * @type {string}
 * @example
 * crgt.clientVersion
 */
const clientVersion = (ua.match(REGEXP_CRGT) || [])[1] || '0';
/**
 * 默认前端正式环境 static.ccrgt.com，其他为测试环境，或者手动在config中配置
 * @type {boolean}
 * @example
 * crgt.isProd
 */
let isProd = window.location.host === 'static.ccrgt.com';

/**
 * 手动设置环境，是否为正式环境
 * @param val {boolean} 是否为正式环境
 */
const setIsProd = (val) => {
  isProd = val;
};

/**
 * 快应用
 * url中带有 channel==='qa'
 * @type {boolean}
 * @example
 * crgt.isQA
 */
const isQA = parseUrlQuery('channel') === 'qa';

export {
  isIOS,
  isAndroid,
  isCRGT,
  isWX,
  clientVersion,
  platform, // app ios, app android, wx, swanmp, browser
  isProd,
  isQQMp,
  isWXMp,
  isQA,
  isSwanMp,
  setIsProd
};
