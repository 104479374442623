/**
 * @namespace router
 */
import { build, setUserInfo, setToken } from './core';
import { loadScript, parseUrlQuery } from './util';
import { loginUrl, JSDCAPPID } from './utils/constants';
import { getConfig } from './config';
import { isQQMp } from './platform';

export default build('router')(invoke => ({
  /**
   * 返回
   * @function router.popBack
   * @param params.force=0 {Number} 1:强制关闭当前webview，0:默认返回上一个历史页面
   * @support ios 1.1
   * @support android 1.1
   * @support wxmp, qqmp
   * @example
   * crgt.router.popBack()
   */
  popBack: {
    common: function ({
      force = 0
    } = {}) {
      return invoke('popBack', {
        force
      });
    },
    mp: () => {
      return invoke('navigateBack', {
        delta: 1,
      });
    },
    browser: () => {
      window.close();
      return Promise.resolve({
        code: 0
      }).catch((e) => {});
    },
    support: {
      iOS: '1.1',
      android: '1.1',
      wxmp: 0,
      qqmp: 0
    }
  },
  /**
   * 打开登录页面
   * @function router.openLogin
   * @support ios 1.1
   * @support android 1.1
   * @support browser
   * @support wxmp, qqmp 微信小程序，qq小程序返回后刷新页面
   * @example
   * crgt.router.openLogin().then((res)=>{
   *  console.log(res);
   * })
   *  // 回参
   *  {
   *    code: 0,
   *    data: {
   *      accessToken: '',
   *    }
   *  }
   */
  openLogin: {
    common: () => {
      return new Promise((resolve, reject) => {
        invoke('openLogin', {}).then(res => {
          if (res && res.code === 0 && res.data && res.data.accessToken) {
            setToken(res.data.accessToken);
          }
          resolve(res);
        }, reject);
      }).catch((e) => {});
    },
    mp: ({ redirectUrl } = {}) => {
      if (parseUrlQuery('appId') === JSDCAPPID || isQQMp) {  // qq小程序和极速打车小程序
        let newRedirectUrl = `${encodeURIComponent(redirectUrl)}`;
        if (!redirectUrl) {  // 默认返回原来的H5页面
          const url = window.location.href.replace(
            /mpCode=[^&]+(&|$)/,
            '');

          newRedirectUrl = `${encodeURIComponent(url)}`;
        }
        invoke('navigateTo', {
          url: `/pages/login/index?webviewUrl=${newRedirectUrl}`,
        });
      } else { // 国铁出行小程序
        let str = 'callback=reload';
        if (redirectUrl) {
          str = `redirectUrl=/pages/activity/index&url=${encodeURIComponent(redirectUrl)}`;
        }
        invoke('navigateTo', {
          url: `/pages/login/index?${str}`,
        });
      }
    },
    browser: (params) => {
      return new Promise((resolve, reject) => {
        if (!window.registerInterceptor) {
          loadScript(loginUrl).then(() => {
            window.registerInterceptor.init(Object.assign({}, getConfig().H5LOGIN, {
              success: ({ token, mobile, icon, nickName, userNo, tokenExpiresTime, refreshTokenExpiresTime, refreshToken }) => {
                setUserInfo({
                  nickName,
                  phoneNumber: mobile,
                  avatar: icon,
                  accessToken: token,
                  accountId: userNo
                });
                setToken(token, {tokenExpiresTime, refreshTokenExpiresTime, refreshToken});
                resolve({
                  code: 0,
                  data: {
                    accessToken: token,
                  },
                });
              },
              error: () => {
                reject();
              }
            }, params));
            window.registerInterceptor.show();
          });
        } else {
          window.registerInterceptor.show();
        }
      }).catch((e) => {});
    },
    support: {
      iOS: '1.1',
      android: '1.1',
      wxmp: 0,
      qqmp: 0,
      browser: 0
    }
  },
  /**
   * 打开H5页面
   *
   * @function router.openUrl
   * @param params.url='' {String} h5链接地址
   * @param params.target=1 {Number}  0为当前webview打开，1为新的webview打开，2为外部浏览器打开，默认为1
   * @example
   * crgt.router.openUrl({
   *   url: 'https://static.ccrgt.com/download',
   *   target: 1,
   * })
   *  // wxmp
   * crgt.router.openUrl({url: 'http://static.ccrgt.com/download'})  // /pages/activity/index?url=http%3A%2F%2Fstatic.ccrgt.com%2Fdownload
   * @support ios 1.1
   * @support android 1.1
   * @support browser
   * @support wxmp, qqmp 打开小程序H5页面
   */
  openUrl: {
    common: function ({
      url = '',
      target = 1
    }) {
      return invoke('openUrl', {
        url: url.trim(),
        target
      });
    },
    mp: ({ url = '', target = 1 } = {}) => {
      const path = `/pages/${isQQMp ? 'webview' : 'activity'}/index?url=${encodeURIComponent(url.trim())}`;
      if (target === 0) {
        return invoke('redirectTo', {
          url: path
        });
      } else {
        return invoke('navigateTo', {
          url: path
        });
      }

    },
    browser: function ({url = '', target = 1}) {
      return new Promise((resolve) => {
        if (target === 2) {
          window.open(url.trim(), '_blank');
        } else {
          window.open(url.trim(), '_self');
        }
        resolve();
      }).catch((e) => {});
    },
    support: {
      iOS: '1.1',
      android: '1.1',
      browser: 0,
      wxmp: 0,
      qqmp: 0
    }
  },
  /**
   * 打开客户端页面
   * @function router.openView
   * @param params.path {String} 路径
   * @param params.params {Object} 路径请求参数
   * @example
   * crgt.router.openView({
   *   path: 'netcar/detail',
   *   params: {  // 对象，路由协议中的入参对象格式
   *     orderId
   *   }
   * })
   * @see {@link 路由协议https://www.tapd.cn/23788911/markdown_wikis/#1123788911001001163 }
   * @support ios 1.1
   * @support android 1.1
   * @support wxmp, qqmp
   */
  openView: {
    common: function (params) {
      return invoke('openView', {
        path: buildUrl({
          ...params,
          host: 'crgt://ccrgt.com'
        })
      });
    },
    mp: (params) => {
      return invoke('navigateTo', {
        url: buildUrl(params),
      });
    },
    support: {
      iOS: '1.1',
      android: '1.1',
      wxmp: 0,
      qqmp: 0
    }
  },
  /**
   * 打开原生页面、跳转tab、开启wifi、下发通知等操作
   * @function router.openProtocol
   * @support ios 2.0.3
   * @support android 2.0.3
   * @example
   * // protocol: crgt/action
   * // 共享汽车详情
   * window.crgt.router.openProtocol({
   *    protocol: 'crgt',
   *    path: 'netcar/detail',
   *    params: {  // 路由协议中的入参
   *      orderId
   *    }
   *  });
   * // 娱乐tab
   * window.crgt.router.openProtocol({
   *    protocol: 'action',
   *    path: 'openTab',
   *    params: {
   *      tab: 'video'
   *    }
   *  });
   * @see {@link 路由协议https://www.tapd.cn/23788911/markdown_wikis/#1123788911001001163 }
   */
  openProtocol: {
    common: function (params) {
      const protocol = params.protocol || 'crgt';
      return invoke('openProtocol', {
        path: buildUrl({
          ...params,
          host: `${protocol}://ccrgt.com`
        })
      });
    },
    support: {
      iOS: '2.0.3',
      android: '2.0.3'
    }
  },
  /**
   * 打开站内地图
   * @function router.openStationMap
   * @param params.stationName {String} 名字（"杭州东站”, “北京西站” )
   * @param params.poi {String} 站点id
   * @support ios 1.1
   * @support android 1.1
   * @example
   * crgt.router.openStationMap({
   *   stationName: '杭州东站'，
   *   poi: '',
   * })
   */
  openStationMap: {
    common: function (params) {
      return invoke('openStationMap', params);
    },
    support: {
      iOS: '1.1',
      android: '1.1'
    }
  },

  /**
   * 拉起支付
   * （无论支付失败与否，都需要重新请求后台订单接口查看是否支付成功，一般失败跳转到订单详情页）
   * @function router.tradePay
   * @param params.orderId {String} 订单id
   * @support ios 1.2
   * @support android 1.2
   * @example
   * crgt.router.tradePay({orderId}).then((res)=>{});
   * // 回参
   * {
   *  code: 0, // 0:成功
   *  errMsg: '',
   *  data: {
   *    type: 1, // 1:微信，2:支付宝（目前只支持微信支付）
   *  }
   * }
   */
  tradePay: {
    // params: {orderId: ''}
    common: function (params) {
      return invoke('tradePay', params);
    },
    support: {
      iOS: '1.2',
      android: '1.2'
    }
  },
  /**
   * 拉起12306绑定
   * @function router.openNumberAccount
   * @example
   * crgt.router.openNumberAccount().then((res)=>{})
   * // 回参
   * {
   *   code: 0 // 0：绑定成功
   * }
   * // 小程序绑定成功会刷新页面
   * @support ios 1.2.6
   * @support android 1.2.6
   * @support wx
   */
  openNumberAccount: {
    common: function (params) {
      return invoke('openNumberAccount', params);
    },
    wxmp: () => {
      invoke('navigateTo', {
        url: '/pages/account-12306-validate/index?callback=reload',
      });
    },
    support: {
      iOS: '1.2.6',
      android: '1.2.6',
      wxmp: '0'
    }
  },
  /**
   * 微信/qq小程序 reLaunch 关闭所有页面，打开到应用内的某个页面
   * @function router.reLaunch
   * @param params.url {String}  需要跳转的应用内页面路径，路径后可以带参数。参数与路径之间使用?分隔，参数键与参数值用=相连，不同参数用&分隔；如 'path?key=value&key2=value2'
   * @example
   * crgt.router.reLaunch({url: ''});
   * @support wxmp qqmp
   */
  reLaunch: {
    mp: ({ url = '' } = {}) => {
      return invoke('reLaunch', {
        url,
      });
    },
    support: {
      wxmp: 0,
      qqmp: 0
    }
  }
}));

const buildUrl = ({ params = {}, path = '', host = '' } = {}) => {
  let search = '';
  let realPath = path.replace(/^\//, '');
  for (const key in params) {
    search += key + '=' + encodeURIComponent(params[key]) + '&';
  }
  search = search.slice(0, -1);
  if (search) {
    if (path.indexOf('?') !== -1) {
      realPath += `&${search}`;
    } else {
      realPath += `?${search}`;
    }
  }
  return `${host}/${realPath}`;
};
