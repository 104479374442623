function getRandom() {
  const randomNum = Math.floor(100000 * Math.random());
  return `${randomNum}${Date.now()}`;
}

export const setSID = function () {
  const sessionId = `s${getRandom()}`;
  return sessionId || 'other';
};

// USERID
export const getUSERID = function ({ CRGTA_CONFIG }) {
  const storage = window.localStorage;
  return storage.getItem(`${CRGTA_CONFIG.prefix}USERID`);
};

export const setUSERID = function ({ CRGTA_CONFIG }) {
  const USERID = `USERID${getRandom()}`;
  const storage = window.localStorage;
  storage.setItem(`${CRGTA_CONFIG.prefix}USERID`, USERID);
  return USERID;
};
