import { sendRequest2Analytics } from '../../utils/request';

function getEID(isDebug) {
  return isDebug ? 'o_customize_performance_15' : 'o_customize_tech_1';
}

function getPerformanceData(CRGTA_CONFIG) {
  const { timing } = window.performance;
  return {
    project_host: CRGTA_CONFIG.project_name, // host
    project_name: window.document.title,
    page: `${window.location.origin}${window.location.pathname}${window.location.hash}`, // url
    page_name: window.document.title,
    bro_type: CRGTA_CONFIG.browser_type, // 浏览器类型
    host_version: window.crgt && window.crgt.clientVersion, // 客户端版本

    redirect_time: timing.redirectEnd - timing.redirectStart,
    dns_query: timing.domainLookupEnd - timing.domainLookupStart,
    tcp: timing.connectEnd - timing.connectStart,
    white: timing.responseStart - timing.navigationStart, // 白屏时间
    packloaded: timing.responseEnd - timing.requestStart, // 首包请求耗时、内容加载完成的时间
    dom_render: timing.domComplete - timing.responseEnd, // dom渲染
    dom_ready: timing.domComplete - timing.navigationStart, // domready时间
    onload: timing.loadEventEnd - timing.navigationStart, // onload时间
    client: 1, // 1网页，2 小程序
  };
}
// 获取性能，抽样30%
export const getPerforms = ({ CRGTA_CONFIG, getRequestData, isDebug }) => {
  // 上报性能
  const percent = 100;
  if (Math.floor(Math.random() * 100) < percent) {
    window.onload = () => {
      setTimeout(() => {
        if (window.performance && window.performance.timing) {
          const reqData = getRequestData({
            EventID: getEID(isDebug),
            Body: {
              ...getPerformanceData(CRGTA_CONFIG),
            },
          });
          sendRequest2Analytics({ CRGTA_CONFIG, data: reqData }); // 发送请求到大数据
        }
      }, 0);
    };
  }
};

// 第一页最后一个请求完成时
export const trackFirstPageLastRequestTime = ({ CRGTA_CONFIG, getRequestData }) => {
  if (window.performance && window.performance.timing) {
    const reqData = getRequestData({
      EventID: getEID(),
      Body: {
        ...getPerformanceData(CRGTA_CONFIG),
        lastreq: Math.floor(Date.now() - window.performance.timing.navigationStart),
      },
    });
    sendRequest2Analytics({ CRGTA_CONFIG, data: reqData }); // 发送请求到大数据
  }
};
