/*
 * @Author: Cookie
 * @Date: 2020-12-11 10:16:03
 * @LastEditors: Cookie
 * @LastEditTime: 2020-12-15 15:44:26
 * @FilePath: /FE-CRGT-SDKJS/src/media.js
 * @Description: 
 */

import {
  build
} from './core';

/**
 * @namespace media
 */
export default build('media')((invoke) => ({
  /**
   * @function media.getPicture
   * @param params.quality=80 {Number} 图片质量0-100
   * @param params.targetWidth {Number}   图片宽度，单位px，不传默认图片大小
   * @param params.destinationType=0 {Number} 0: base64, 1: file_uri(file_url暂不支持)
   * @example
   * crgt.media.getPicture({
   *  quality: 80,
   *  targetWidth: 100,
   *  destinationType: 0,
   * }).then(()=>{})
   * // 回参
   * {
   *    data: {
   *        imageURI: '',  // file URI或base64
   *    },
   *    code: 0,    // 0:成功，1:取消，2: 未授权
   * }
   * @support ios 1.1.3
   * @support android 1.1.5
   */
  getPicture: {
    common: function (params) {
      const options = Object.assign({
        quality: 50
      }, params);
      return invoke('getPicture', options);
    },
    support: {
      iOS: '1.1.3',
      android: '1.1.5'
    }
  },
  /**
   * @function media.makeImagesToVideo
   * @param params.imgs=[] 传入图片列表
   * @example
   * crgt.media.makeImagesToVideo({
   *  imgs: [],
   * }).then(()=>{})
   * // 回参
   * {
   *    data: {
   *        onResult(success: bool),
   *        onProgress(progress: float)
   *    },
   *    code: 0,    // 0:成功，1:取消，2: 未授权
   * }
   * @support ios 1.1.3
   * @support android 1.1.5
   */
  makeImagesToVideo: {
    common: function (params) {
      return invoke('makeImagesToVideo', params);
    },
    support: {
      iOS: '1.1.3',
      android: '1.1.5'
    }
  },
  /**
   * @function media.saveImg
   * @param params.imgStr='' 传入的图片字符
   * @example
   * crgt.media.makeImagesToVideo({
   *  imgStr: String,
   * }).then(()=>{})
   * // 回参
   * {
   *    data: {
   *    },
   *    code: 0,    // 0:成功，1:取消，2: 未授权
   * }
   * @support ios 1.1.3
   * @support android 1.1.5
   */
  saveImg: {
    common: function (params) {
      return invoke('saveImg', params);
    },
    support: {
      iOS: '1.1.3',
      android: '1.1.5'
    }
  }
}));
