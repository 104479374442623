import {
  clientVersion,
  platform,
  isAndroid,
  isCRGT,
  isWX,
  isQQMp,
  isWXMp,
  isSwanMp,
} from './platform';

import { axiosInstance, loadScript, parseUrlQuery } from './util';
import { initWx, getWXTokenByCode } from './wx';
import { initQQ, getQQTokenByCode } from './qq';
import { initSwan, getSwanTokenByCode } from './swan';
import { TOKENKEY, refreshTokenUrl, getUserInfoUrl, REFRESH_TOKEN_KEY, USERINFO_KEY } from './utils/constants';
import * as CRGTA from '../analytics/src/h5';
import storage from './storage';
const aSupports = {}; // 缓存保存所有api支持的app版本
let USERINFO = storage.get(USERINFO_KEY) || {}; // accountId, phoneNumber, nickName, avatar
/**
 * 比较两个版本号 *.*.*
 * @private
 * @param a
 * @param b
 * @returns {number}  当a<b返回-1, 当a==b返回0, 当a>b返回1, 当a或b非法则返回-1
 */
function compareVersion(a, b) {
  let i, l, r, len;

  a = String(a).split('.');
  b = String(b).split('.');

  for (i = 0, len = Math.max(a.length, b.length); i < len; i++) {
    l = (isFinite(a[i]) && Number(a[i])) || 0;
    r = (isFinite(b[i]) && Number(b[i])) || 0;
    if (l < r) {
      return -1;
    } else if (l > r) {
      return 1;
    }
  }
  return 0;
}

/**
 * @private
 * @desc 创建 api 方法, 把指定 api 包装为固定的调用形式
 * @param {String} name 需创建的命名空间，如：'crgt.ui.openUrl'
 * @param {Object} data 接口配置信息（包含接口在iOS/android/browser端的执行以及各平台支持的版本）
 * @example
 * buildAPI("ui.openUrl", {
 *     common: ()=>{}, // android, ios通用调用
 *     android: ()=>{},  // android特殊调用方式，覆盖common
 *     iOS: ()=>{},      // iOS
 *     browser: ()=>{}, // 某些 api 可能有浏览器兼容的方式
 *     wx: ()=>{}, // wx/小程序浏览器中
 *     support: {  // api兼容的app版本
 *        iOS: '1.0',
 *        android: '1.0',
 *        browser: 0,
 *     }
 * })*/
function buildAPI(name, data) {
  let plat = platform;
  let func = () => new Promise((resolve, reject) => reject());
  if ((plat === 'android' || plat === 'iOS') && data.common) { // ios, android 使用common方法
    // app
    func = data.common;
  } else if ((plat === 'wxmp' || plat === 'qqmp') && data.mp) { // 小程序使用mp方法
    func = data.mp;
  } else {
    // 都没有的，默认用浏览器方法
    func = data['browser'];
    plat = 'browser';
  }
  if (data[platform]) func = data[platform];  // 平台有指定的方法
  if (!func) func = () => new Promise((resolve, reject) => resolve({ code: -10086 }));

  // 用于 supportVersion 判断
  if (data.support && data.support[plat]) {
    aSupports[name] = data.support[plat];
  }
  return func;
}

/**
 * @desc 检查当前客户端版本是否支持该接口，返回 true 则表示支持该接口；false 则不支持。
 * @param {String} apiName 接口名字
 * @return {Boolean}
 * @example
 * crgt.support("data.getUserInfo"); // return true | false
 */
function support(apiName) {
  const support = aSupports[apiName];

  if (!support) {
    return false;
  }
  // 版本区间检查
  const vers = support.split('-');
  if (vers.length === 1) {
    return compare(vers[0]) > -1;
  } else {
    return compare(vers[0]) > -1 && compare(vers[1]) < 1;
  }
}

/**
 * 调用native
 * @private
 * @param {string} nsName
 * @param {string} methodName
 * @param {object} options  参数
 */
function invokeClient(nsName, methodName, options = {}) {
  return new Promise(function (resolve, reject) {
    ready(() => {
      if (nsName === 'request' && methodName === 'post') {
        const start_at = Date.now();
        let api_code = -1;
        const api_url = `https://${options.host}/${options.path}`;
        window.cordova.exec((res) => {
          const end_at = Date.now();
          api_code = res.code;
          const body = {
            api_url: api_url,
            start_at,
            end_at,
            during: end_at - start_at,
            api_code,
          };
          CRGTA.Event.trackApiPerformance(body);
          resolve(res);
        }, () => {
          const end_at = Date.now();
          api_code = -1;
          const body = {
            api_url: api_url,
            start_at,
            end_at,
            during: end_at - start_at,
            api_code,
          };
          CRGTA.Event.trackApiPerformance(body);
          reject();
        }, nsName, methodName, [options || {}]);
      } else {
        window.cordova.exec(resolve, reject, nsName, methodName, [options || {}]);
      }
    });
  });
}

function callMiniProgram(methodName, options = {}) {
  return new Promise(function (resolve, reject) {
    ready(() => {
      const miniProgram = isQQMp ? window.qq.miniProgram : window.wx.miniProgram;
      const params = Object.assign({}, options, {
        success: res => {
          resolve(res);
        },
        fail: () => {
          reject();
        },
      });
      miniProgram[methodName](params);
    });
  });
}

/**
 * @desc 比较版本号，返回比较结果（-1，0，1）。
 * @param {String} version
 * @returns {number} 如果当前 clientVersion 小于给定版本ver，返回 -1，等于返回 0，大于返回 1
 * @example
 * crgt.clientVersion == "2.0.5";
 * crgt.compare("2.0.6");// 返回-1, 当前客户端版本低于2.0.6版本
 * crgt.compare("2.0.0");// 返回1，  当前客户端版本大于2.0.0版本
 */
function compare(ver) {
  return compareVersion(clientVersion, ver);
}

/**
 * @private
 * @param nsName
 * @return {function(*=, *=)}
 */
function wrapInvoke(nsName) {
  return function (methodName, options) {
    if (isCRGT) {
      return invokeClient(nsName, methodName, options);
    } else {
      return callMiniProgram(methodName, options);
    }
  };
}

/**
 * @private
 * @param nsName
 * @return {function(*): {}}
 */
function build(nsName) {
  const invoke = wrapInvoke(nsName);
  return function (callback) {
    const obj = callback(invoke);
    return Object.keys(obj).reduce((next, key) => {
      next[key] = buildAPI(nsName + '.' + key, obj[key]);
      return next;
    }, {});
  };
}

let callbacks = [];
let isReady = false;

/**
 * cordova/weixinSdk 初始化完成后调用
 * @param callback
 */
function ready(callback) {
  if (typeof callback === 'function') {
    callbacks.push(callback);
  }
  if (isReady) {
    callbacks.forEach(fn => {
      fn && fn();
    });
    callbacks = [];
  }
}

/**
 * @private
 * @param cb
 * @param clear
 * @return {string}
 */
function createCallbackFunction(cb, clear) {
  const fnName = `crgtcallback_${Date.now()}_${Math.ceil(Math.random() * 100000)}`;
  window[fnName] = response => {
    cb(response);
    if (clear) {
      clearCallbackFunction(fnName);
    }
  };
  return fnName;
}

/**
 * @private
 * @param functionName
 */
function clearCallbackFunction(functionName) {
  // IE8 throws an exception when you try to delete a property on window
  // http://stackoverflow.com/a/1824228/751089
  try {
    delete window[functionName];
  } catch (e) {
    window[functionName] = undefined;
  }
}

// 初始化，将accesstoke写入window中
/**
 * @private
 */
function initialize() {
  initPlatform().then(() => {
    if (isCRGT) {
      document.addEventListener(
        'deviceready',
        () => {
          isReady = true;
          ready();
        },
        false
      );
    } else {
      isReady = true;
      ready();
    }
  });
}

/**
 * 初始化平台，app加载cordova.js，微信加载微信sdk
 * @private
 * @return {Promise<void>}
 */
function initPlatform() {
  if (isWX || isWXMp) {
    return initWx();
  } else if (isQQMp) {
    return initQQ();
  } else if (isSwanMp) {
    return initSwan();
  } else if (isAndroid && isCRGT) {
    return loadScript('./cordova.js');
  } else {
    return Promise.resolve();
  }
}

/**
 * 异步获取token,兼容app、微信小程序、qq小程序、H5
 * @function getToken
 * @return {Promise<token>} 未登录返回null，已登录返回token
 * @example
 * crgt.getToken().then((res)=>{
 *  console.log(res); // eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhY2NvdW50SWQiOiIxMTAzNTQ3MDcyNTU4MTQxNDQwIiwibW9iaWxlIjoiMTg3NjgxNDAxMjQiLCJ0eXAiOiJKV1QiLCJleHAiOjE1NjU0OTczOTEsImNvcnBDb2RlIjoiQ1JHVCIsImFsZyI6IkhTMjU2IiwiaWF0IjoxNTY0NjMzMzkxLCJsb2dpblNvdXJjZSI6IjIifQ.e5jRNX14UjUG8yIfkRcdGJVBZWo7gsQ86WpXd9T88BQ
 * });
 */
function getToken() {
  return new Promise(async resolve => {
    let token = window[TOKENKEY] || parseUrlQuery('token');

    if (!token && platform === 'browser') {
      token = storage.get(TOKENKEY);
    }
    if (!token) {
      if (isCRGT) {
        invokeClient('data', 'getUserInfo').then(res => {
          cb(res);
        });
      } else if (isWXMp) {
        getWXTokenByCode(parseUrlQuery('mpCode')).then(res => {
          cb(res);
        });
      } else if (isQQMp) {
        getQQTokenByCode(parseUrlQuery('mpCode')).then(res => {
          cb(res);
        });
      } else if (isSwanMp) {
        getSwanTokenByCode(parseUrlQuery('mpCode')).then(res => {
          cb(res);
        });
      } else {
        // h5 browser
        // token过期，refreshToken
        await refreshToken();
        resolve(storage.get(TOKENKEY));
      }
      const cb = res => {
        if (res.code === 0) {
          const data = res.data || {};
          setUserInfo(data);
          setToken(data.accessToken);
          resolve(window[TOKENKEY]);
        } else {
          // 失败也resolve
          resolve(token);
        }
      };
    } else {
      window[TOKENKEY] = token;
      resolve(token);
    }
  });
}

/**
 * 设置token
 * @param token
 * @param refreshToken
 * @param refreshTokenExpiresTime
 * @param tokenExpiresTime
 */
const setToken = (token, { refreshToken, refreshTokenExpiresTime, tokenExpiresTime } = {}) => {
  if (!token) return;
  if (platform === 'browser' || platform === 'wx') {
    // h5浏览器才使用localstorage
    storage.set(TOKENKEY, token, tokenExpiresTime, true);
    if (refreshToken) {
      storage.set(REFRESH_TOKEN_KEY, refreshToken, refreshTokenExpiresTime, true);
    }
  }
  window[TOKENKEY] = token;
};

/**
 * 清空token
 */
const clearToken = () => {
  storage.remove(TOKENKEY);
  storage.remove(REFRESH_TOKEN_KEY);
  storage.remove(USERINFO_KEY);
  window[TOKENKEY] = '';
};

/**
 * 设置用户信息
 * @param userInfo
 * @return userInfo
 */
const setUserInfo = userInfo => {
  USERINFO = userInfo || {};
  try {
    storage.set(USERINFO_KEY, USERINFO);
  } catch (e) {
    console.warn(e);
  }
  return USERINFO;
};
/**
 * 获取用户信息，如果没有，但已登录，则通过接口获取信息
 * @return {Promise}
 */
const getUserInfo = async () => {
  // 浏览器
  if (!USERINFO.accountId && platform === 'browser') {
    const token = await getToken();
    if (token) {
      const result = await axiosInstance.post(
        getUserInfoUrl,
        {
          token,
          isSign: '',
          sign: '',
          cguid: '',
          timeStamp: ''
        }
      );
      const res = result.data || {};
      if (res && res.code === 0) {
        const { nickName, mobile, icon, userNo } = res.data || {};
        const userInfo = {
          nickName,
          phoneNumber: mobile,
          avatar: icon,
          accountId: userNo
        };
        setUserInfo(userInfo);
      }
    }
  }

  return USERINFO;
};

// 刷新token
const refreshToken = () => {
  const refreshToken = storage.get(REFRESH_TOKEN_KEY);
  if (!refreshToken) return null;
  return new Promise((resolve, reject) => {
    axiosInstance.post(
      refreshTokenUrl,
      {
        params: {
          refreshToken
        },
        isSign: '',
        sign: '',
        cguid: '',
        timeStamp: ''
      }
    ).then((result) => {
      const res = result.data || {};
      if (res && res.code === 0) {
        const { token, refreshToken, tokenExpiresTime, refreshTokenExpiresTime } = res.data || {};
        resolve(res);
        setToken(token, { refreshToken, tokenExpiresTime, refreshTokenExpiresTime });
      } else {
        resolve(res);
      }
    });
  });
};

initialize();

export {
  compare,
  invokeClient,
  buildAPI,
  support,
  build,
  ready,
  createCallbackFunction,
  getToken,
  setToken,
  clearToken,
  getUserInfo,
  setUserInfo,
  refreshToken
};
