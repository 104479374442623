/*
 * @Author: Lina
 * @Date: 2020-09-07 18:45:11
 * @LastEditors: Lina
 * @LastEditTime: 2020-09-09 17:10:37
 */
import { swanSdkUrl, SWANAPPID, wxGetUserInfoUrl } from './utils/constants';
import { loadScript, axiosInstance, parseUrlQuery } from './util';
import { getConfig } from './config';
import { isSwanMp } from './platform';

const { USESWANSDK } = getConfig();

/**
 * 初始化微信
 * 1）加载微信sdk
 * 2）若时微信浏览器，通过微信config接口注入权限验证配置
 * @return {Promise<any>}
 * @private
 */
function initSwan() {
  return new Promise((resolve, reject) => {
    if (USESWANSDK) {
      loadScript(swanSdkUrl).then(() => {
        // if (isSwanMp) { // 在小程序里，不需要initConfig
        resolve();
        // }
      });
    }
    resolve();
  }).catch((e) => {});
}

let GETTOKEN_LOCK = false;
let curGetTokenPromise = null;
/**
 * 小程序里获取用户信息、token
 * @param jscode {string} 小程序url中带的参数code
 * @return {Promise<any>}
 * @private
 */

const getSwanTokenByCode = (jscode) => {
  if (GETTOKEN_LOCK) {
    return curGetTokenPromise;
  } else {
    GETTOKEN_LOCK = true;
    curGetTokenPromise = new Promise((resolve, reject) => {
      _getSwanTokenByCode(jscode).then((token) => {
        GETTOKEN_LOCK = false;
        resolve(token);
      });
    }).catch((e) => {});
    return curGetTokenPromise;
  }
};

const _getSwanTokenByCode = (jscode) => {
  return new Promise((resolve, reject) => {
    axiosInstance.post(
      wxGetUserInfoUrl,
      {
        params: {
          jscode: jscode || parseUrlQuery('mpCode'),
          appId: parseUrlQuery('appId') || SWANAPPID,
          channel: parseUrlQuery('mpChannelId') || ''
        },
        isSign: '',
        sign: '',
        cguid: '',
        timeStamp: ''
      }
    ).then((result) => {
      const res = result.data || {};
      if (res && res.code === 0) {
        const { nickName, openId, icon, mobile, token, userNo } = res.data || {};
        return resolve({
          code: 0,
          data: {
            nickName,
            phoneNumber: mobile,
            avatar: icon,
            openId,
            accessToken: token,
            accountId: userNo
          }
        });
      } else {
        return resolve({ code: -1 });
      }
    });
  }).catch((e) => {});
};

export {
  initSwan,
  getSwanTokenByCode
};
