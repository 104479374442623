// Polyfill
if (!Object.values) {
  Object.values = function (obj) {
    if (obj !== Object(obj)) { throw new TypeError('Object.values called on a non-object'); }
    const val = []; let key;
    // eslint-disable-next-line no-restricted-syntax
    for (key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        val.push(obj[key]);
      }
    }
    return val;
  };
}
