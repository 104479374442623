/**
 * 定时清除缓存
 */
class TempStorage {
  constructor() {
    this.initRun();
  }

  initRun() {
    const reg = new RegExp('__separator__');
    let data = window.localStorage;
    let list = Object.keys(data);
    if (list.length > 0) {
      list.map((key) => {
        if (!reg.test(key)) {
          let now = Date.now();
          let separator = data[`${key}__separator__`] || Date.now + 1;
          if (now >= separator) {
            this.remove(key);
          }
        }
        return key;
      });
    }
  }

  /**
   * 获取缓存
   * @param String $key  key
   * @return value;
   */
  get(key) {
    const separatorTime = localStorage.getItem(`${key}__separator__`);
    const timeout = parseInt(separatorTime, 10);

    // 过期失效
    if (timeout) {
      if (Date.now() > timeout) {
        this.remove(key);
        return '';
      }
    }
    let data = localStorage.getItem(key);
    try {
       data = JSON.parse(data);
    } catch (e) {
      console.warn(e);
    }
    return data;
  }

  /**
   * 设置缓存
   * @param String $key       key
   * @param String $value     value（支持字符串、json、数组、boolean等等）
   * @param Number $timeout   过期时间（单位：分钟）| 时间戳 不设置时间即为永久保存
   * @param isTimeStatmp 传入是否为时间戳
   * @return value;
   */
  set(key, value, timeout = 0, isTimeStatmp = false) {
    let _timeout = parseInt(timeout, 10);
    localStorage.setItem(key, JSON.stringify(value));
    if (!isTimeStatmp && _timeout) {
      _timeout = Date.now() + 1000 * 60 * _timeout;
    }
    if (_timeout) {
      localStorage.setItem(`${key}__separator__`, _timeout);
    } else {
      localStorage.removeItem(`${key}__separator__`);
    }
    return value;
  }

  /**
   * 清除某个item缓存
   * @param String $key       key
   */
  remove(key) {
    localStorage.removeItem(key);
    localStorage.removeItem(`${key}__separator__`);
    return undefined;
  }

  /**
   * 清除所有缓存
   */
  clear() {
    let data = window.localStorage;
    let list = Object.keys(data);
    if (list.length > 0) {
      list.map((key) => {
        this.remove(key);
      });
    }
  }
}

export default new TempStorage();
