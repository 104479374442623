/* eslint-disable no-param-reassign */
// 如果在App中
function getAppNetworkType(requestHeaderData) {
  try {
    return window.crgt.device.getNetworkType().then((res) => {
      if (res.code === 0) {
        requestHeaderData.NT = res.data.type;
      }
    });
  } catch (error) {
    return Promise.resolve(true);
  }
}

// 获取手机pb,pt,osv
function getAppDeviceInfo(requestCommonData) {
  try {
    return window.crgt.device.getDeviceInfo().then((res) => {
      if (res.code === 0) {
        requestCommonData.PB = res.data.model;
        requestCommonData.PT = res.data.modelVersion;
        requestCommonData.OSV = res.data.systemVersion;
      }
    });
  } catch (error) {
    return Promise.resolve(true);
  }
}

// 检测是否再app中
export const checkIsInApp = ({ CRGTA_CONFIG, requestHeaderData, requestCommonData }) => new Promise((resolve, reject) => {
  if (window.crgt && window.crgt.isCRGT) {
    CRGTA_CONFIG.browser_type = 'CRGT_APP';
    Promise.all([
      getAppNetworkType(requestHeaderData),
      getAppDeviceInfo(requestCommonData),
    ]).then(() => {
      resolve();
    }).catch((error) => {
      reject(error);
    }).finally(() => {
    });
  } else {
    resolve();
  }
});
