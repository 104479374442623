import { build, createCallbackFunction } from './core';

/**
 * @namespace ui
 */
export default build('ui')(invoke => ({
  /**
   * 设置titlebar title（默认是html中的title标签内容）
   * @function ui.setTitle
   * @param params.title {String} 可以为空字符串
   * @param params.color {String}  16进制,e.g.#ffffff
   * @support ios 1.1
   * @support android 1.1
   * @support browser, wxmp,qqmp,wx
   * @example
   * crgt.ui.setTitle({
   *   title: '掌上高铁',
   *   color: '#000000'
   * })
   */
  setTitle: {
    common: function(params) {
      return invoke('setTitle', params);
    },
    mp: ({ title = '' } = {}) => {
      document.title = title;
      return Promise.resolve({
        code: 0,
        data: {},
      }).catch((e) => {});
    },
    wx: ({ title = '' } = {}) => {
      document.title = title;
      return Promise.resolve({
        code: 0,
        data: {},
      }).catch((e) => {});
    },
    browser: ({ title = '' } = {}) => {
      document.title = title;
      return Promise.resolve({
        code: 0,
        data: {},
      }).catch((e) => {});
    },
    support: {
      iOS: '1.1',
      android: '1.1',
      browser: '0',
      wx: '0',
      wxmp: '0',
      qqmp: '0',
    },
  },
  /**
   * 设置左上角按钮
   * @function ui.setLeftButton
   * @param params.type=0 {Number}  0:返回，1:关闭，2:自定义点击回调(1.2.0版本 )
   * @param params.onClick {Function} 点击回调，默认返回(1.2.0版本)
   * @param params.backButton=true {Boolean} 支持硬件返回按钮设置
   * @param params.title='' {String} 左上角按钮文字
   * @param params.icon='' {String} 左上角按钮图标， base6格式(48*48大小）
   * @param params.color {String} 文字颜色
   * @support ios 1.2
   * @support android 1.2
   * @example
   * crgt.ui.setLeftButton({
   *   type: 2,
   *   onClick: ()=>{ alert(1); }
   * })
   */
  setLeftButton: {
    common: function(params) {
      const options = Object.assign({}, params, {
        onClick: createCallbackFunction(params.onClick || function() {}),
      });
      return invoke('setLeftButton', options);
    },
    support: {
      iOS: '1.2',
      android: '1.2',
    },
  },

  /**
   *
   * @desc 设置右上角按钮
   * @function ui.setRightButton
   * @param params.hidden=false {Boolean} 是否隐藏
   * @param params.onClick {Function} 点击回调，data.index:第几个按钮被点击
   * @param params.items {Array<Object>} 右上角按钮
   * @support ios 1.2
   * @support android 1.2
   * @example
   * crgt.ui.setRightButton({
   *   onClick: (data)=>{console.log(data.index)},
   *   items: [{
   *     title: '客服,
   *     color: '',
   *     icon: ''
   *   }]
   * })
   */
  setRightButton: {
    common: function(params) {
      const options = Object.assign({}, params, {
        onClick: createCallbackFunction(params.onClick || function() {}),
      });
      return invoke('setRightButton', options);
    },
    support: {
      iOS: '1.2',
      android: '1.2',
    },
  },
  /**
   * 设置webview titlebar样式
   * @function ui.setWebViewBehavior
   * @param params.swipeBack=0 {Boolean} ios是否支持右滑关闭（1:否，0:支持）
   * @param params.navBgColor='-1' {String}  导航栏背景颜色 （颜色为16进制，如#ffffff，'-1'透明）
   * @param params.navTextColor {String} 导航栏文字颜色（目前不会设置到左侧按钮颜色）
   * @support ios 1.2
   * @support android 1.2
   * @example
   * crgt.ui.setWebViewBehavior({
   *   navBgColor: '-1'
   * })
   */
  setWebViewBehavior: {
    common: function(params) {
      return invoke('setWebViewBehavior', params);
    },
    support: {
      iOS: '1.2',
      android: '1.2',
    },
  },
}));
