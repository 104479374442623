import { build } from './core';

/**
 * @namespace third
 */
export default build('third')((invoke) => ({
  /**
   * 共享汽车核身
   * @function third.shareCarIndentify
   * @param params.type=0 {Number} 0: 完整核身流程，1:刷脸
   * @example
   * crgt.third.shareCarIndentify({type:1}).then();
   * // 回参
   * {
   *  data: {
   *    result: 0, // 0:成功
   *  }
   * }
   * @support ios 1.1
   * @support android 1.1
   */
  shareCarIndentify: {
    common: function ({
      type = 0
    }) {
      return invoke('shareCarIndentify', {
        type
      });
    },
    support: {
      iOS: '1.1',
      android: '1.1'
    }
  },

  /**
   * App广告视频接受结果
   * @function third.setUpRewardedVideoAd
   * @param params.type=0 {Number}
   * @example
   * crgt.third.setUpRewardedVideoAd({type:0}).then();
   * // 回参
   * {
   *  data: {
   *    result: 0, // 0:成功
   *  }
   * }
   * @support ios 1.1
   * @support android 1.1
   */
  setUpRewardedVideoAd: {
    common: function ({type = 0}) {
      return invoke('setUpRewardedVideoAd', {type});
    },
    support: {
      iOS: '1.1',
      android: '1.1'
    }
  }
}));
