import { sendRequest, sendRequest2Front } from '../../utils/request';

const UAParser = require('../../utils/ua-parser.min.js');

const parser = new UAParser();
const result = parser.getResult();
const ua_info = JSON.stringify(result);
// 错误埋点配置
const ErrorConfig = {
  EventType: 600000,
  EventID: 890002,
};

// 获取错误
// status 1 JS运行时错误， 2资源加载错误 js css img， 3接口错误 4 业务异常
export const getError = function ({ CRGTA_CONFIG, getRequestData }) {
  // window.onerror = (msg, url, line, col, error) => {
  // JS运行时错误
  // eslint-disable-next-line consistent-return
  window.onerror = (msg, url, line, col) => {
    if (msg !== 'Script error.' && !url) {
      // 跨域
      return true;
    }
    setTimeout(() => {
      // 获取localStorage
      const localVal = {};
      CRGTA_CONFIG.LOCAL_S.map((keyname) => {
        localVal[`${keyname}`] = window.localStorage.getItem(keyname);
        return keyname;
      });
      const ERR_MSG = JSON.stringify({
        msg,
        localStorage: localVal,
      });
      const reqData = getRequestData({
        EventType: ErrorConfig.EventType,
        EventID: ErrorConfig.EventID,
        Body: {
          PNAME: CRGTA_CONFIG.project_name,
          PNAME_NOTE: window.document.title,
          URL: window.location.href,
          UA_INFO: ua_info,
          BRO_TYPE: CRGTA_CONFIG.browser_type,
          ERR_MSG,
          ERR_URL: url,
          ERR_LINE: line,
          ERR_COL: col,
          status: 1,
          version_host: window.crgt && window.crgt.clientVersion,
        },
      });
      sendRequest({ CRGTA_CONFIG, data: reqData }); // 发送请求到大数据
      sendRequest2Front({
        CRGTA_CONFIG,
        data: reqData,
        url: '/receive/error',
      }); // 发送请求到前端服务器
    }, 0);
  };
  // 资源加载错误 js css img
  window.addEventListener('error', (event) => {
    const srcE = event.srcElement;
    if (
      srcE instanceof window.HTMLScriptElement
      || srcE instanceof window.HTMLLinkElement
      || srcE instanceof window.HTMLImageElement
    ) {
      const { target } = event;
      if (!(/cordova/.test(target.outerHTML))) {
        setTimeout(() => {
          const reqData = getRequestData({
            EventType: ErrorConfig.EventType,
            EventID: ErrorConfig.EventID,
            Body: {
              PNAME: CRGTA_CONFIG.project_name,
              PNAME_NOTE: window.document.title,
              URL: window.location.href,
              UA_INFO: ua_info,
              BRO_TYPE: CRGTA_CONFIG.browser_type,
              ERR_MSG: target.outerHTML,
              ERR_URL: target.src,
              // ERR_LINE: line,
              // ERR_COL: col,
              status: 2,
              version_host: window.crgt && window.crgt.clientVersion,
            },
          });
          sendRequest({ CRGTA_CONFIG, data: reqData }); // 发送请求到大数据
          sendRequest2Front({
            CRGTA_CONFIG,
            data: reqData,
            url: '/receive/error',
          }); // 发送请求到前端服务器
        }, 0);
      }
    }
  },
  true);
  // 接口错误，所有http请求都是基于xmlHttpRequest或者fetch封装的。所以要捕获全局的接口错误，方法就是封装xmlHttpRequest或者fetch
  // 封装xmlHttpRequest
  if (!window.XMLHttpRequest) return;
  const xmlhttp = window.XMLHttpRequest;
  const _oldOpen = xmlhttp.prototype.open;
  const _oldSend = xmlhttp.prototype.send;
  let method = 'GET';
  let url = '';
  const postData = [];

  const _handleEvent = function (event) {
    if (event && event.currentTarget && event.currentTarget.status !== 200) {
      const { status, responseText } = event.currentTarget;
      // 自定义错误上报
      // event.currentTarget.onerror();
      // 获取localStorage
      const localVal = {};
      CRGTA_CONFIG.LOCAL_S.map((keyname) => {
        localVal[`${keyname}`] = window.localStorage.getItem(keyname);
        return keyname;
      });
      const ERR_MSG = JSON.stringify({
        method,
        status,
        responseText,
        postData,
        localStorage: localVal,
      });
      setTimeout(() => {
        const reqData = getRequestData({
          EventType: ErrorConfig.EventType,
          EventID: ErrorConfig.EventID,
          Body: {
            PNAME: CRGTA_CONFIG.project_name,
            PNAME_NOTE: window.document.title,
            URL: window.location.href,
            UA_INFO: ua_info,
            BRO_TYPE: CRGTA_CONFIG.browser_type,
            ERR_MSG,
            ERR_URL: url,
            // ERR_LINE: line,
            // ERR_COL: col,
            status: 3,
            version_host: window.crgt && window.crgt.clientVersion,
          },
        });
        sendRequest({ CRGTA_CONFIG, data: reqData }); // 发送请求到大数据
        sendRequest2Front({
          CRGTA_CONFIG,
          data: reqData,
          url: '/receive/error',
        }); // 发送请求到前端服务器
      }, 0);
    }
  };
  xmlhttp.prototype.open = function (...rest) {
    [method, url] = rest;
    return _oldOpen.apply(this, rest);
  };
  xmlhttp.prototype.send = function (...rest) {
    if (rest && rest.length > 0 && rest[0]) {
      rest.map((obj) => {
        postData.push(obj);
        return obj;
      });
    }
    if (this.addEventListener) {
      this.addEventListener('error', _handleEvent);
      this.addEventListener('load', _handleEvent);
      this.addEventListener('abort', _handleEvent);
    } else {
      const _oldStateChange = this.onreadystatechange;
      this.onreadystatechange = function (event2) {
        if (this.readyState === 4) {
          _handleEvent(event2);
        }
        // eslint-disable-next-line no-unused-expressions
        _oldStateChange && _oldStateChange.apply(this, rest);
      };
    }
    return _oldSend.apply(this, rest);
  };
};

// 发送业务异常 4
export const sendBizErr = function ({ CRGTA_CONFIG, getRequestData, BizData }) {
  // 获取localStorage
  const localVal = {};
  CRGTA_CONFIG.LOCAL_S.map((keyname) => {
    localVal[`${keyname}`] = window.localStorage.getItem(keyname);
    return keyname;
  });
  const ERR_MSG = JSON.stringify({
    localStorage: localVal,
    BizData: JSON.stringify(BizData),
  });
  const reqData = getRequestData({
    EventType: ErrorConfig.EventType,
    EventID: ErrorConfig.EventID,
    Body: {
      PNAME: CRGTA_CONFIG.project_name,
      PNAME_NOTE: window.document.title,
      URL: window.location.href,
      UA_INFO: ua_info,
      BRO_TYPE: CRGTA_CONFIG.browser_type,
      ERR_MSG,
      // ERR_URL: url,
      // ERR_LINE: line,
      // ERR_COL: col,
      status: 4,
      version_host: window.crgt && window.crgt.clientVersion,
    },
  });
  sendRequest({ CRGTA_CONFIG, data: reqData }); // 发送请求到大数据
  sendRequest2Front({
    CRGTA_CONFIG,
    data: reqData,
    url: '/receive/error',
  }); // 发送请求到前端服务器
};
