/*
 * @Author: Lina
 * @Date: 2019-12-12 20:49:29
 * @LastEditors: Lina
 * @LastEditTime: 2020-09-07 18:48:04
 */
import { isProd } from './platform';
import { deepCopy } from './util';
import { TOKENKEY, regUrl, verifyCodeUrl } from './utils/constants';

let CONFIG = {
  DEBUG: !isProd, // 是否需要console调试面板
  CRGTA: {},
  DSN: 'http://2805ff01205243ac8264bad0cc29d742@sentry.crgt.xyz/1',
  SENTRY: {  // sentry init 配置
    ignoreErrors: ['WeixinJSBridge', 'x5onSkinSwitch'],
  },
  H5LOGIN: {
    storage: 'localStorage',
    type: 'local', // local 本地，open 新页面
    tokenKey: TOKENKEY, // token存储的key, 不要改！！
    closeIcon: true,
    activityImgUrl: '',
  },
  WXJSAPILIST: [
    'onMenuShareAppMessage',
    'onMenuShareTimeline',
    'onMenuShareQQ',
    'onMenuShareWeibo',
    'onMenuShareQZone',
  ],
  WXDEBUG: false, // 微信sdk调试
  USEWXSDK: true, // 默认在微信环境里会引入微信sdk
  USEQQSDK: true, // 默认在qq小程序里会引入qq sdk
  USESWANSDK: true, // 默认在百度小程序里会引入qq sdk
  ISPROD: isProd,
};
const initConfig = config => {
  config.H5LOGIN = Object.assign({
    verifyCodeUrl,
    url: regUrl,
  }, config.H5LOGIN);
  CONFIG = deepCopy(CONFIG, config);
  return CONFIG;
};

const getConfig = () => {
  return CONFIG;
};

export { initConfig, getConfig };
