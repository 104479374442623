import {build, invokeClient, compare} from './core';

/**
 * @namespace cashier
 */
export default build('cashier')((invoke) => ({
  /**
   * @function cashier.toCashier
   * @desc 低于2。4。0， 调用router.tradePay方法
   * @param params.orderId='' {String} 订单id
   * @example
   * crgt.cashier.toCashier({
   *  orderId: '',
   * }).then(()=>{})
   * // 回参
   * {
   *    code: 0,    // 0:成功
   * }
   * @support ios 2.4.0
   * @support android 2.4.0
   */
  toCashier: {
    common: function (params) {
      if (compare('2.4.0') >= 0) {
        return invoke('toCashier', params);
      } else {
        return invokeClient('router', 'tradePay', params);
      }
    },
    support: {
      iOS: '2.4.0',
      android: '2.4.0'
    }
  }
}));
