import axios from 'axios';
import { wxGetApiSignUrl, wxGetUserInfoUrl, wxSdkUrl, APPID } from './utils/constants';
import { loadScript, axiosInstance, parseUrlQuery } from './util';
import { getConfig } from './config';
import { isWXMp } from './platform';

const { WXJSAPILIST, USEWXSDK } = getConfig();

/**
 * 初始化微信
 * 1）加载微信sdk
 * 2）若时微信浏览器，通过微信config接口注入权限验证配置
 * @return {Promise<any>}
 * @private
 */
function initWx() {
  return new Promise((resolve, reject) => {
    if (USEWXSDK) {
      loadScript(wxSdkUrl).then(() => {
        if (isWXMp) { // 在小程序里，不需要initWxConfig
          resolve();
        } else {
          _initWxConfig().then(() => {
            window.wx && window.wx.ready(() => {
              resolve();
            });
          });
        }
      });
    } else {
      resolve();
    }
  }).catch((e) => {});
}


/**
 * 初始化微信配置
 * @return {Promise<any>}
 * @private
 */
function _initWxConfig({ jsApiList = [] } = {}) {
  const apilist = WXJSAPILIST.concat(
    jsApiList);
  return new Promise((resolve, reject) => {
    axios.post(
      wxGetApiSignUrl,
      {
        params: {
          url: window.location.href
        }
      }).then(result => {
        const res = result.data || {};
        if (res && res.code === 0) {
          const { appId, timestamp, nonceStr, signature } = res.data || {};
          window.wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId, // 必填，公众号的唯一标识
            timestamp, // 必填，生成签名的时间戳
            nonceStr, // 必填，生成签名的随机串
            signature, // 必填，签名，见附录1
            jsApiList: apilist   // jsApiList 必填，需要使用的JS接口列表，所有JS接口列表见附录2
          });
          resolve();
        } else {
          reject();
        }
      }).catch(() => {
        reject();
      });
  }).catch((e) => {});
}
let GETTOKEN_LOCK = false;
let curGetTokenPromise = null;
/**
 * 小程序里获取用户信息、token
 * @param jscode {string} 小程序url中带的参数code
 * @return {Promise<any>}
 * @private
 */

const getWXTokenByCode = (jscode) => {
  if (GETTOKEN_LOCK) {
    return curGetTokenPromise;
  } else {
    GETTOKEN_LOCK = true;
    curGetTokenPromise = new Promise((resolve, reject) => {
      _getWXTokenByCode(jscode).then((token) => {
        GETTOKEN_LOCK = false;
        resolve(token);
      });
    }).catch((e) => {});
    return curGetTokenPromise;
  }
};

const _getWXTokenByCode = (jscode) => {
  return new Promise((resolve, reject) => {
    const postLogin = parseUrlQuery('postLogin');
    if (postLogin == '1'){
      return resolve({ code: -1 });
    }
    axiosInstance.post(
      wxGetUserInfoUrl,
      {
        params: {
          jscode: jscode || parseUrlQuery('mpCode'),
          appId: parseUrlQuery('appId') || APPID,
          channel: parseUrlQuery('mpChannelId') || ''
        },
        isSign: '',
        sign: '',
        cguid: '',
        timeStamp: ''
      }
    ).then((result) => {
      const res = result.data || {};
      if (res && res.code === 0) {
        const { nickName, openId, icon, mobile, token, userNo } = res.data || {};
        return resolve({
          code: 0,
          data: {
            nickName,
            phoneNumber: mobile,
            avatar: icon,
            openId,
            accessToken: token,
            accountId: userNo
          }
        });
      } else {
        return resolve({ code: -1 });
      }
    });
  }).catch((e) => {});
};

export {
  initWx,
  getWXTokenByCode
};
