/**
 * @namespace H5_Event
 */
import { trackFirstPageLastRequestTime } from '../components/performances';
import { trackApiPerformance } from '../components/performances/api';
import { sendBizErr } from '../components/errors';

export default ({
  CRGTA_CONFIG,
  realSendRequest,
  getRequestData,
}) => ({
  /**
   * track是trackPV、trackClick的汇总
   * @function H5_Event.track
   * @param EventType {String} (必填)事件类型 页面访问类型300000、页面点击类型500000
   * @param EventID {String} (必填)事件ID，查看埋点对照表 若是临时活动：页面访问事件810000、页面点击事件820000
   * @param UID {String} token不填，app内部将自动获取window._ACCESSTOKEN_，小程序url的query：token
   * @param Body {Object} 其他字段，查看埋点对照表
   * @param Body.EID {String} 临时事件ID，查看埋点对照表(临时活动需要填写)
   * @example
   * CRGTA.Event.track({
   *   EventType: Number, // (必填)事件类型
   *   EventID: Number/String, // (必填)事件ID
   *   UID: null, // (选填) token不填，app内部将自动获取window._ACCESSTOKEN_，小程序url的query：token// 第三方浏览器需自己填写，初始化中有就从初始化获取
   *   Body:{
   *    EID: '', // （选填）临时事件ID
   *     // 其他字段，查看埋点对照表
   *   }
   * });
    */
  track(config) {
    realSendRequest(config);
  },


  /**
   * 页面访问，不用区分pv、uv
   * @function H5_Event.trackPV
   * @param config {Object} {}
   * @param config.EventID {String} (必填)事件ID，查看埋点对照表
   * @param config.UID {String} token不填，app内部将自动获取window._ACCESSTOKEN_，小程序url的query：token
   * @param config.Body {Object} 其他字段，查看埋点对照表
   * @param config.Body.EID {String} 临时事件ID，查看埋点对照表(临时活动需要填写)
   * @param event {Boolean} 是否是临时活动
   * @example
   * CRGTA.Event.trackPV({
    *   EventID: String,
    *   UID: '',
    *   Body: {
    *     EID: '',
    *   }
    * }, false)
    */
  trackPV(config, event = false) {
    const preset = event ? { EventType: 300000, EventID: 810000 } : { EventType: 300000 };
    const newConfig = Object.assign(config, preset);

    realSendRequest(newConfig);
  },


  /**
   * 点击事件
   * @function H5_Event.trackClick
   * @param config {Object} {}
   * @param config.EventID {String} (必填)事件ID，查看埋点对照表
   * @param config.UID {String} token不填，app内部将自动获取window._ACCESSTOKEN_，小程序url的query：token
   * @param config.Body {Object} 其他字段，查看埋点对照表
   * @param config.Body.EID {String} 临时事件ID，查看埋点对照表(临时活动需要填写)
   * @param event {Boolean} 是否是临时活动
   * @example
   * CRGTA.Event.trackClick({
   *   EventID: String,
   *   UID: '',
   *   Body: {
  *     EID: '',
   *   }
   * }, false)
   */
  trackClick(config, event = false) {
    const preset = event ? { EventType: 500000, EventID: 820000 } : { EventType: 500000 };
    const newConfig = Object.assign(config, preset);
    realSendRequest(newConfig);
  },


  /**
   * 页面停留时长，每5s上报一次
   * @function H5_Event.trackTime
   * @param EventType {Number} 若是临时活动：页面访问类型300000
   * @param EventID {String} 事件id，若是临时活动：页面访问事件810000
   * @param UID {String} 用户ID
   * @param Body {Object} 其他字段，查看埋点对照表
   * @param Body.EID {String} 临时活动的事件ID
   * @example
   * // 非临时
   * const timer = CRGTA.Event.trackTime({
   *   EventType: 300000,// (必填)事件类型，页面访问类型300000、页面点击类型500000
   *   EventID: String,
   *   UID: '',
   *   Body: {
   *   }
   * })
   * // 临时活动
   * const timer = CRGTA.Event.trackTime({
   *   EventType: 300000,
   *   EventID: 810000,
   *   Body: {
   *     EID: '', // 临时活动的事件ID
   *   }
   * })
   * // 停止页面停留时长统计
   * timer.stop();
   */
  trackTime(config) {
    // 切换到后台，暂停计时
    let isPaused = false;
    window.document.addEventListener('visibilitychange', () => {
      isPaused = window.document.hidden;
    });
    // 定时, 每五秒钟发送一次请求
    let timer = null;
    let startTime = 0;
    realSendRequest(config, startTime);
    timer = setInterval(() => {
      if (!isPaused) {
        startTime += 5;
        realSendRequest(config, startTime);
      }
    }, 5000);
    // 清除timer
    const func = {
      stop: () => {
        clearInterval(timer);
      },
    };
    return func;
  },


  /**
   * 第一页最后一个请求完成时
   * @function H5_Event.trackFirstPage
   * @example
   * CRGTA.Event.trackFirstPage()
   */
  trackFirstPage() {
    trackFirstPageLastRequestTime({ CRGTA_CONFIG, getRequestData });
  },


  /**
   * 发送业务异常
   * @function H5_Event.sendBizErr
   * @example
   * CRGTA.Event.sendBizErr({
   *   a: 10,
   *   b: 20
   * })
   */
  sendBizErr(BizData) {
    sendBizErr({ CRGTA_CONFIG, getRequestData, BizData });
  },


  /**
   * 追踪接口性能
   * @function H5_Event.trackApiPerformance
   * @param api_url {String} 接口地址url
   * @param start_at {Number} 请求开始时间戳（ms）
   * @param end_at {Number} 请求结束时间戳（ms）
   * @param during {Number} 加载时长
   * @param api_code {Number} 接口返回的code
   * @param [client=1] {Number} 客户端类型 1、h5 2、小程序
   * @example
   * CRGTA.Event.trackApiPerformance({
   *   api_url: 'https://www.baidu.com',
   *   start_at: 1571102710762,
   *   end_at: 1571102710762,
   *   during: 300,
   *   api_code: 1024, // (status != 200) code = -1
   * })
   */
  trackApiPerformance(BizData) {
    trackApiPerformance({ CRGTA_CONFIG, getRequestData, BizData });
  },
});
